import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  IconX,
  IconSmartHome,
  IconSend,
  IconReservedLine,
  IconUser,
  IconLogout,
  IconTrashFilled,
} from "@tabler/icons-react";
import { deleteAccount, logoutUser } from "../../services/auth/authService";
import CustomButton from "../buttons/CustomButton";
import { useTranslation } from "react-i18next";

const MobileMenu: React.FC<{
  isMobileMenuOpen: boolean;
  toggleMobileMenu: () => void;
}> = ({ isMobileMenuOpen, toggleMobileMenu }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [deleteError, setDeleteError] = useState("");
  const { t } = useTranslation();

  // Function to determine if a route is active
  const isActive = (path: string) => location.pathname === path;

  const handleLogout = async () => {
    try {
      await logoutUser();
      console.log("Logout successfully");
      navigate("/");
    } catch (error: any) {
      console.log("Failed to logout: ", error.message);
    }
  };

  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
  };

  const handleLogoutConfirm = () => {
    setIsLogoutModalOpen(false);
    handleLogout();
  };

  const handleLogoutCancel = () => {
    setIsLogoutModalOpen(false);
  };

  const handleDeleteAccount = async () => {
    if (!deleteReason) {
      setDeleteError("Please select a reason for deleting your account.");
      return;
    }

    try {
      await deleteAccount();
      console.log("Account deleted successfully");
      navigate("/");
    } catch (error: any) {
      console.log("Failed to delete account: ", error.message);
    }
  };

  return (
    <>
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="fixed top-0 right-0 w-[250px] h-full bg-white shadow-lg z-50 flex flex-col p-6">
            <div className="flex justify-between items-center mb-10">
              <span className="text-xl font-semibold">{t("menu")}</span>
              <button onClick={toggleMobileMenu}>
                <IconX size={24} className="text-gray-600" />
              </button>
            </div>
            <nav className="flex flex-col gap-4 flex-grow">
              <Link
                to="/user/proposals"
                className={`flex items-center gap-2 ${
                  isActive("/user/proposals") ? "text-primary_green" : ""
                }`}
              >
                <IconSmartHome
                  size={20}
                  className={
                    isActive("/user/proposals") ? "text-primary_green" : ""
                  }
                />
                <span>{t("proposals")}</span>
              </Link>
              <Link
                to="/user/send-requests"
                className={`flex items-center gap-2 ${
                  isActive("/user/send-requests") ? "text-primary_green" : ""
                }`}
              >
                <IconSend
                  size={20}
                  className={
                    isActive("/user/send-requests") ? "text-primary_green" : ""
                  }
                />
                <span>{t("sendRequests")}</span>
              </Link>
              <Link
                to="/user/receive-requests"
                className={`flex items-center gap-2 ${
                  isActive("/user/receive-requests") ? "text-primary_green" : ""
                }`}
              >
                <IconReservedLine
                  size={20}
                  className={
                    isActive("/user/receive-requests")
                      ? "text-primary_green"
                      : ""
                  }
                />
                <span>{t("receiveRequests")}</span>
              </Link>
              <Link
                to="/user/profile"
                className={`flex items-center gap-2 ${
                  isActive("/user/profile") ? "text-primary_green" : ""
                }`}
              >
                <IconUser
                  size={20}
                  className={
                    isActive("/user/profile") ? "text-primary_green" : ""
                  }
                />
                <span>{t("profile")}</span>
              </Link>
            </nav>
            <div className="mt-auto flex flex-col gap-4">
              <button
                className="flex items-center gap-2 text-red-800"
                onClick={handleLogoutClick}
              >
                <IconLogout size={20} className="text-red-800" />
                <span>{t("logout")}</span>
              </button>
              <button
                className="flex items-center gap-2 text-zinc-600"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                <IconTrashFilled size={20} className="text-zinc-600" />
                <span>{t("deleteAcc")}</span>
              </button>
            </div>
          </div>
        </div>
      )}

      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center font-DMSans">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[300px]">
            <h2 className="text-[18px] font-semibold mb-2">{t("deleteAcc")}</h2>
            <p className="mb-4 text-gray-500">
              {t("Are you sure you want to delete your account?")}
            </p>
            <p className="mb-4 text-gray-500">
              {t("Please select a reason")}::
            </p>
            <select
              className="w-full mb-4 p-2 border rounded "
              value={deleteReason}
              onChange={(e) => setDeleteReason(e.target.value)}
            >
              <option value="">{t("Select a reason")}</option>
              <option value="Privacy concerns">{t("Privacy concerns")}</option>
              <option value="Not finding matches">
                {t("Not finding matches")}
              </option>
              <option value="Found a partner">{t("Found a partner")}</option>
              <option value="Other">{t("Other")}</option>
            </select>
            {deleteError && <p className="text-red-500 mb-4">{deleteError}</p>}
            <div className="flex justify-end gap-4">
              <CustomButton
                text="cancel"
                color="rgb(229 231 235)"
                textColor="black"
                onClick={() => setIsDeleteModalOpen(false)}
              />

              <CustomButton
                text="delete"
                color=" rgb(220 38 38)"
                textColor="white"
                onClick={handleDeleteAccount}
              />
            </div>
          </div>
        </div>
      )}

      {isLogoutModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center font-DMSans">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[300px]">
            <h2 className="text-[18px] font-semibold mb-2">{t("logout")}</h2>
            <p className="mb-4 text-gray-500">
              {t("Are you sure you want to logout?")}
            </p>
            <div className="flex justify-end gap-4">
              <CustomButton
                text="cancel"
                color="rgb(229 231 235)"
                textColor="black"
                onClick={handleLogoutCancel}
              />

              <CustomButton
                text="logout"
                color=" rgb(220 38 38)"
                textColor="white"
                onClick={handleLogoutConfirm}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileMenu;
