import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { app } from "../../firebase/firebase";

const db = getFirestore(app);

export const getAllUserDetails = async () => {
  try {
    const q = query(
      collection(db, "users"),
      where("isAccountApproved", "==", true)
    );
    const querySnapshot = await getDocs(q);
    const userDetails = [];
    querySnapshot.forEach((doc) => {
      userDetails.push({ id: doc.id, ...doc.data() });
    });
    return userDetails;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getSentRequests = async (currentUserEmail) => {
  try {
    const q = query(
      collection(db, "requests"),
      where("senderEmail", "==", currentUserEmail)
    );
    const querySnapshot = await getDocs(q);
    const sentRequests = [];
    querySnapshot.forEach((doc) => {
      sentRequests.push(doc.data().receiverEmail);
    });
    return sentRequests;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getFilteredUserDetails = async (currentUserEmail) => {
  try {
    // Get the list of users to whom the current user has sent requests
    const sentRequests = await getSentRequests(currentUserEmail);

    // Get the list of all approved users
    const q = query(
      collection(db, "users"),
      where("isAccountApproved", "==", true)
    );
    const querySnapshot = await getDocs(q);
    const userDetails = [];
    querySnapshot.forEach((doc) => {
      const userData = { id: doc.id, ...doc.data() };
      // Exclude users to whom the current user has sent requests
      if (!sentRequests.includes(userData.email)) {
        userDetails.push(userData);
      }
    });
    return userDetails;
  } catch (error) {
    throw new Error(error.message);
  }
};
