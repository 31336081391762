import React, { useState } from "react";
import { defaultAvatar, imagePicker, whiteLogo } from "../../assets/images";
import {
  CustomButton,
  CustomDropdown,
  CustomInput,
  Footer,
} from "../../components";
import {
  caste,
  districts,
  education,
  incomeOptions,
  job,
  marriageOptions,
  race,
  religion,
} from "../../const/DropdownOptions";
import { useAuth } from "../../contexts/authContexts";
import { useNavigate } from "react-router-dom";
import { storePersonalDetails } from "../../services/auth/authService";
import { storage } from "../../firebase/firebase"; // Import storage from firebase.js
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { message } from "antd";

interface PersonalDetailsType {
  name: string;
  district: string;
  job: string;
  race: string;
  caste: string;
  religion: string;
  education: string;
  marriage: string;
  income: string;
  gender: string;
  day: string;
  month: string;
  year: string;
  profilePicture?: string;
  otherDetails: string;
}

const PersonalDetails = () => {
  const { currentUser } = useAuth();
  const [name, setName] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedJob, setSelectedJob] = useState("");
  //const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState<Date | null>(null);
  const [selectedRace, setSelectedRace] = useState("");
  const [selectedCaste, setSelectedCaste] = useState("");
  const [selectedReligion, setSelectedReligion] = useState("");
  const [selectedEducation, setSelectedEducation] = useState("");
  const [selectedMarriageStatus, setSelectedMarriageStatus] = useState("");
  const [selectedIncome, setSelectedIncome] = useState("");
  const [nameError, setNameError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [jobError, setJobError] = useState("");
  const [raceError, setRaceError] = useState("");
  const [casteError, setCasteError] = useState("");
  const [religionError, setReligionError] = useState("");
  const [educationError, setEducationError] = useState("");
  const [marriageError, setMarriageError] = useState("");
  const [incomeError, setIncomeError] = useState("");
  const [otherDetails, setOtherDetails] = useState("");

  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState(defaultAvatar);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDropdownChange =
    (
      setter: React.Dispatch<React.SetStateAction<string>>,
      setError: React.Dispatch<React.SetStateAction<string>>
    ) =>
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setter(e.target.value);
      if (e.target.value === "") {
        setError("Please select an option");
      } else {
        setError("");
      }
    };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRegister = async () => {
    if (!currentUser) {
      setNameError("User not authenticated");
      return;
    }

    let hasError = false;

    if (!name) {
      setNameError("Name must be filled out");
      hasError = true;
    } else {
      setNameError("");
    }

    if (!selectedDistrict) {
      setDistrictError("District must be selected");
      hasError = true;
    } else {
      setDistrictError("");
    }

    if (!selectedJob) {
      setJobError("Job must be selected");
      hasError = true;
    } else {
      setJobError("");
    }

    if (!selectedRace) {
      setRaceError("Race must be selected");
      hasError = true;
    } else {
      setRaceError("");
    }

    if (!selectedCaste) {
      setCasteError("Caste must be selected");
      hasError = true;
    } else {
      setCasteError("");
    }

    if (!selectedReligion) {
      setReligionError("Religion must be selected");
      hasError = true;
    } else {
      setReligionError("");
    }

    if (!selectedEducation) {
      setEducationError("Education status must be selected");
      hasError = true;
    } else {
      setEducationError("");
    }

    if (!selectedMarriageStatus) {
      setMarriageError("Marriage status must be selected");
      hasError = true;
    } else {
      setMarriageError("");
    }

    if (!selectedIncome) {
      setIncomeError("Monthly income must be selected");
      hasError = true;
    } else {
      setIncomeError("");
    }

    if (hasError) {
      return;
    }

    let day = "";
    let month = "";
    let year = "";

    if (birthday) {
      day = birthday.getDate().toString().padStart(2, "0");
      month = (birthday.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
      year = birthday.getFullYear().toString();
    }

    const personalDetails: PersonalDetailsType = {
      name,
      district: selectedDistrict,
      job: selectedJob,
      race: selectedRace,
      caste: selectedCaste,
      religion: selectedReligion,
      education: selectedEducation,
      marriage: selectedMarriageStatus,
      income: selectedIncome,
      gender: gender,
      day,
      month,
      year,
      otherDetails,
    };

    setLoading(true);

    try {
      if (selectedImage) {
        const storageRef = ref(storage, `user_avatars/${currentUser.uid}`);
        await uploadBytes(storageRef, selectedImage);
        const downloadURL = await getDownloadURL(storageRef);
        personalDetails.profilePicture = downloadURL;
      }

      await storePersonalDetails(currentUser.email, personalDetails);
      message.success("Personal details added successfully");
      navigate("/user/profile");
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <nav className="w-[100%] z-[999] bg-primary_green h-[60px] lg:h-[80px] px-8 lg:px-[100px] flex flex-row items-center justify-between border-b-[0.5px] border-[#DDDDDD] font-DMSans">
        <div className="flex items-center gap-2 ">
          <img src={whiteLogo} alt="" className="h-[50px] lg:h-[60px]" />
        </div>
      </nav>

      <div className="flex-grow">
        <div className="px-8 lg:px-[100px] py-8  flex flex-col items-center">
          <div className="relative rounded-[8px] h-[150px] w-[150px] bg-gray-50  overflow-hidden">
            <img src={imageUrl} alt="" className="h-full w-full object-cover" />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="imageInput"
            />
            <label htmlFor="imageInput">
              <img
                src={imagePicker}
                alt=""
                className="absolute top-0 right-0 h-6 w-6 cursor-pointer"
              />
            </label>
          </div>

          {/* Textfields */}
          <div className="flex flex-wrap justify-center gap-2 lg:gap-4 py-6 px-0 lg:px-10">
            <div className="w-[300px]   ">
              <CustomInput
                label="Name"
                placeholder="Enter name"
                value={name}
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => setName(e.target.value)}
                errorMessage={nameError}
              />
            </div>
            <div className="w-[300px] ">
              <CustomDropdown
                label="Gender"
                options={[{ label: "Male" }, { label: "Female" }]}
                value={gender}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setGender(e.target.value)
                }
                // errorMessage={genderError}
              />
            </div>
            <div className="w-[300px]  mb-4">
              <label className="block text-[14px] font-[400] text-custom_black mb-1">
                {t("Birthday")}
              </label>
              <DatePicker
                selected={birthday}
                onChange={(date: Date | null) => setBirthday(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText={t("Select your birthday")}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className={`w-[300px]  px-4 py-2 border rounded-md transition-all
      ${birthday ? "border-[#009A74] shadow-md" : "border-gray-300"}
      focus:outline-none focus:border-[#009A74] hover:border-[#009A74]
      focus:ring-2 focus:ring-[#009A74] text-[14px]`}
              />
            </div>

            <div className="w-[300px]  ">
              <CustomDropdown
                label="District"
                options={districts}
                value={selectedDistrict}
                onChange={handleDropdownChange(
                  setSelectedDistrict,
                  setDistrictError
                )}
                errorMessage={districtError}
              />
            </div>
            <div className="w-[300px]  ">
              <CustomDropdown
                label="Job"
                options={job}
                value={selectedJob}
                onChange={handleDropdownChange(setSelectedJob, setJobError)}
                errorMessage={jobError}
              />
            </div>
            <div className="w-[300px]  ">
              <CustomDropdown
                label="Race"
                options={race}
                value={selectedRace}
                onChange={handleDropdownChange(setSelectedRace, setRaceError)}
                errorMessage={raceError}
              />
            </div>
            <div className="w-[300px]  ">
              <CustomDropdown
                label="Caste"
                options={caste}
                value={selectedCaste}
                onChange={handleDropdownChange(setSelectedCaste, setCasteError)}
                errorMessage={casteError}
              />
            </div>
            <div className="w-[300px]  ">
              <CustomDropdown
                label="Religion"
                options={religion}
                value={selectedReligion}
                onChange={handleDropdownChange(
                  setSelectedReligion,
                  setReligionError
                )}
                errorMessage={religionError}
              />
            </div>
            <div className="w-[300px]  ">
              <CustomDropdown
                label="Education status"
                options={education}
                value={selectedEducation}
                onChange={handleDropdownChange(
                  setSelectedEducation,
                  setEducationError
                )}
                errorMessage={educationError}
              />
            </div>
            <div className="w-[300px]  ">
              <CustomDropdown
                label="Marriage status"
                options={marriageOptions}
                value={selectedMarriageStatus}
                onChange={handleDropdownChange(
                  setSelectedMarriageStatus,
                  setMarriageError
                )}
                errorMessage={marriageError}
              />
            </div>
            <div className="w-[300px]  ">
              <CustomDropdown
                label="Monthly income"
                options={incomeOptions}
                value={selectedIncome}
                onChange={handleDropdownChange(
                  setSelectedIncome,
                  setIncomeError
                )}
                errorMessage={incomeError}
              />
            </div>
            <div className="w-[300px]">
              <label className="block text-[14px] font-[400] text-custom_black mb-1">
                {t("Other Details (Optional)")}
              </label>
              <textarea
                value={otherDetails}
                onChange={(e) => setOtherDetails(e.target.value)}
                placeholder={t("Enter any additional details")}
                className="w-full h-10 px-4 py-2 border rounded-md text-[14px] border-gray-300 focus:outline-none focus:border-[#009A74] hover:border-[#009A74]"
              />
            </div>
          </div>
          <div className="flex items-center justify-center ">
            <CustomButton
              text={loading ? "Loading..." : "register"}
              width="300px"
              onClick={handleRegister}
              disabled={loading}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PersonalDetails;
