import React from "react";
import { Navigate } from "react-router-dom";
import { Commet } from "react-loading-indicators";
import { useAuth } from "../../contexts/authContexts";

const ProtectedRoute = ({ children }) => {
  const { currentUser, loading } = useAuth();

  // Wait for loading to complete before checking user authentication
  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Commet color="#009A74" size="medium" text="" textColor="#009A74" />
      </div>
    );
  }

  // Redirect if there is no current user after loading is done
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
