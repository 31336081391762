export const HomeCardTexts = [
  {
    title: "homeCard.postAdForFree",
    description: "homeCard.postAdForFreeDescription",
  },
  {
    title: "homeCard.dualLanguageSupport",
    description: "homeCard.dualLanguageSupportDescription",
  },
  {
    title: "homeCard.completePrivacyAssurance",
    description: "homeCard.completePrivacyAssuranceDescription",
  },
];
