import React, { useEffect, useState } from "react";
import {
  HomeCard,
  Navbar,
  TitleText,
  ParagraphText,
  ProposalCard,
  Footer,
  CustomButton,
} from "../../components";
import { HomeCardTexts } from "../../const/constants";
import { useAuth } from "../../contexts/authContexts";
import { useNavigate } from "react-router-dom";
import { getAllUserDetails } from "../../services/proposal/proposalService";
import { menAvatar, sithuminaBg, womenAvatar } from "../../assets/images";
import { IconChevronsDown, IconLogin, IconUserPlus } from "@tabler/icons-react";
import NoPostsAvailable from "../../components/loaders/NoPost";
import { useTranslation } from "react-i18next";

const Homepage = () => {
  const { currentUser } = useAuth();
  const [userDetails, setUserDetails] = useState<any[]>([]);
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/user/proposals");
    } else {
      const fetchUsersDetails = async () => {
        try {
          const details = await getAllUserDetails();
          setUserDetails(details);
        } catch (error: any) {
          console.error("Failed to fetch user details:", error.message);
        }
      };
      fetchUsersDetails();
    }
  }, [currentUser, navigate]);

  const handleShowAll = () => {
    setShowAll(true);
  };

  return (
    <>
      <Navbar isLoggedIn={!!currentUser} />
      {/* // Hero */}
      <div
        className="w-[100%] px-8 lg:px-[100px] py-10 lg:py-16 bg-white flex flex-col items-center justify-center font-DMSans"
        style={{
          backgroundImage: `url(${sithuminaBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h1 className="text-primary_green text-[40px] lg:text-[64px] font-bold text-center">
          {t("sriLankan")}
        </h1>
        <h1 className="text-golden text-[40px] lg:text-[64px] font-bold text-center">
          {t("matrimonial")}
        </h1>
        <h3 className="text-custom_black text-[14px] lg:text-[20px] font-normal text-center py-[42px] lg:py-8">
          {t("homeTitle")}
        </h3>
        <div className="lg:hidden  flex-col-reverse flex justify-center items-center gap-4">
          <CustomButton
            text="login"
            icon={<IconLogin size={16} />}
            width="150px"
            border="1px solid #009a4a"
            link="/login"
          />
          <CustomButton
            text="register"
            icon={<IconUserPlus size={16} />}
            border="1px solid #009a4a"
            color="transparent"
            textColor="#009a4a"
            width="150px"
            link="/register"
          />
        </div>
      </div>

      {/* cards */}
      <div className="w-[100%]  bg-primary_green flex flex-col lg:flex-row justify-center items-center font-DMSans gap-4">
        <div className="px-8 lg:px-[100px] py-6 flex flex-col lg:flex-row gap-4">
          {HomeCardTexts.map((item) => (
            <HomeCard
              key={item.title}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
      </div>

      {/* Proposals */}
      <div className="px-8 lg:px-[100px] py-8 lg:py-12 bg-white font-DMSans flex flex-col justify-center items-center gap-4">
        <TitleText title="proposals" />
        <ParagraphText description="Discover a wide range of thoughtfully crafted proposals, each offering a unique opportunity to find your ideal life partner. Browse through and connect with genuine individuals seeking meaningful connections." />

        {/* Proposal cards */}
        <div className="flex flex-wrap justify-center gap-4 lg:gap-8 px-10 py-6">
          {userDetails.length > 0 ? (
            userDetails
              .slice(0, showAll ? userDetails.length : 18)
              .map((user) => (
                <ProposalCard
                  key={user.id}
                  photo={
                    user.profilePicture ||
                    (user.gender === "Male" ? menAvatar : womenAvatar)
                  }
                  name={user.name}
                  day={user.day}
                  month={user.month}
                  year={user.year}
                  district={user.district}
                  job={user.job}
                  height={user.height}
                  weight={user.weight}
                  race={user.race}
                  caste={user.caste}
                  religion={user.religion}
                  education={user.education}
                  income={user.income}
                  marriage={user.marriage}
                  alcohol={user.alcohol}
                  brothers={user.brothers}
                  sisters={user.sisters}
                  gender={user.gender}
                  otherDetails={user.otherDetails}
                />
              ))
          ) : (
            <NoPostsAvailable />
          )}
        </div>
        {showAll ? (
          <CustomButton
            text="See more"
            rightIcon={<IconChevronsDown size={16} />}
            color="transparent"
            textColor="#009A74"
            border="1px solid #009A74"
            onClick={() => navigate("/register")}
          />
        ) : (
          <CustomButton
            text="Show All"
            rightIcon={<IconChevronsDown size={16} />}
            color="transparent"
            textColor="#009A74"
            border="1px solid #009A74"
            onClick={handleShowAll}
          />
        )}
      </div>

      <Footer />
    </>
  );
};

export default Homepage;
