import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import {
  Homepage,
  Login,
  PersonalDetails,
  Register,
  NICnumber,
  Profile,
  Proposals,
  SendRequests,
  ReceiveRequests,
} from "./pages";
import ProtectedRoute from "./services/auth/ProtectedRoute";
import { AuthProvider } from "./contexts/authContexts";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="" element={<Homepage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register/nic" element={<NICnumber />} />
          <Route
            path="/register/personal-details"
            element={<PersonalDetails />}
          />
          <Route
            path="/user/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/proposals"
            element={
              <ProtectedRoute>
                <Proposals />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/send-requests"
            element={
              <ProtectedRoute>
                <SendRequests />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/receive-requests"
            element={
              <ProtectedRoute>
                <ReceiveRequests />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
