import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface InputProps {
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  errorMessage?: string;
  disabled?: boolean;
}

const CustomInput: React.FC<InputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  type = "text",
  disabled = false,
  errorMessage,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full mb-4">
      {label && (
        <label
          htmlFor={label}
          className="text-[14px] font-[400] text-zinc-600 mb-1"
        >
          {t(label)}
        </label>
      )}

      <input
        type={type}
        placeholder={placeholder ? t(placeholder) : ""}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={`px-4 py-2 border rounded-md transition-all 
          ${isFocused ? "border-[#009A74] shadow-md" : "border-gray-300"} 
          ${errorMessage ? "border-red-500" : ""}
          focus:outline-none focus:border-[#009A74] hover:border-[#009A74] 
          focus:ring-2 focus:ring-[#009A74] text-[14px]`}
        style={{
          boxShadow: isFocused ? "0 0 5px rgba(0, 154, 116, 0.5)" : "none",
          transition: "border-color 0.3s, box-shadow 0.3s",
        }}
      />

      {/* Error message */}
      {errorMessage && (
        <span className="text-red-500 mt-1 text-[12px]">{errorMessage}</span>
      )}
    </div>
  );
};

export default CustomInput;
