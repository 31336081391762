import { db } from "../../firebase/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

const getUserDetailsByEmail = async (email) => {
  const userQuery = query(collection(db, "users"), where("email", "==", email));
  const querySnapshot = await getDocs(userQuery);
  if (!querySnapshot.empty) {
    return querySnapshot.docs[0].data();
  } else {
    throw new Error("User not found");
  }
};

export { getUserDetailsByEmail };
