import {
  getFirestore,
  collection,
  addDoc,
  doc,
  query,
  getDocs,
  Timestamp,
  where,
  getDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { app } from "../../firebase/firebase";

const db = getFirestore(app);

export const sendRequest = async (
  senderEmail: string,
  receiverEmail: string
) => {
  try {
    const senderDocRef = doc(db, "users", senderEmail);
    const receiverDocRef = doc(db, "users", receiverEmail);

    const request = {
      sender: senderEmail,
      receiver: receiverEmail,
      status: "pending",
      createdAt: Timestamp.fromDate(new Date()),
    };

    await addDoc(collection(senderDocRef, "send_requests"), request);
    await addDoc(collection(receiverDocRef, "received_requests"), request);
  } catch (error: any) {
    console.error("Error sending request:", error);
    throw new Error(error.message);
  }
};

export const getAcceptedSendRequests = async (email: string) => {
  try {
    const userDocRef = doc(db, "users", email);
    const q = query(
      collection(userDocRef, "send_requests"),
      where("status", "==", "accepted")
    );
    const querySnapshot = await getDocs(q);

    // Map requests and include additional user data if needed
    const requests = await Promise.all(
      querySnapshot.docs.map(async (docSnapshot) => {
        const data = docSnapshot.data();
        const reeiverDoc = await getDoc(doc(db, "users", data.receiver));
        const receiverData = reeiverDoc.exists() ? reeiverDoc.data() : {};

        return {
          id: docSnapshot.id,
          ...data,
          receiverInfo: receiverData, // Add the sender's additional information here
        };
      })
    );

    return requests;
  } catch (error: any) {
    console.error("Error fetching accepted send requests:", error);
    throw new Error(error.message);
  }
};

export const getPendingSendRequests = async (email: string) => {
  try {
    const userDocRef = doc(db, "users", email);
    const q = query(
      collection(userDocRef, "send_requests"),
      where("status", "==", "pending")
    );
    const querySnapshot = await getDocs(q);

    // Map requests and include additional user data if needed
    const requests = await Promise.all(
      querySnapshot.docs.map(async (docSnapshot) => {
        const data = docSnapshot.data();
        const reeiverDoc = await getDoc(doc(db, "users", data.receiver));
        const receiverData = reeiverDoc.exists() ? reeiverDoc.data() : {};

        return {
          id: docSnapshot.id,
          ...data,
          receiverInfo: receiverData, // Add the sender's additional information here
        };
      })
    );

    return requests;
  } catch (error: any) {
    console.error("Error fetching accepted send requests:", error);
    throw new Error(error.message);
  }
};

export const getRejectedSendRequests = async (email: string) => {
  try {
    const userDocRef = doc(db, "users", email);
    const q = query(
      collection(userDocRef, "send_requests"),
      where("status", "==", "rejected")
    );
    const querySnapshot = await getDocs(q);

    // Map requests and include additional user data if needed
    const requests = await Promise.all(
      querySnapshot.docs.map(async (docSnapshot) => {
        const data = docSnapshot.data();
        const reeiverDoc = await getDoc(doc(db, "users", data.receiver));
        const receiverData = reeiverDoc.exists() ? reeiverDoc.data() : {};

        return {
          id: docSnapshot.id,
          ...data,
          receiverInfo: receiverData, // Add the sender's additional information here
        };
      })
    );

    return requests;
  } catch (error: any) {
    console.error("Error fetching accepted send requests:", error);
    throw new Error(error.message);
  }
};

export const getPendingReceivedRequests = async (email: string) => {
  try {
    const userDocRef = doc(db, "users", email);
    const q = query(
      collection(userDocRef, "received_requests"),
      where("status", "==", "pending")
    );
    const querySnapshot = await getDocs(q);

    const requests = await Promise.all(
      querySnapshot.docs.map(async (docSnapshot) => {
        const data = docSnapshot.data();
        const senderDoc = await getDoc(doc(db, "users", data.sender));
        const senderData = senderDoc.exists() ? senderDoc.data() : {};

        return {
          id: docSnapshot.id,
          ...data,
          senderInfo: senderData, // Add sender's additional information here
        };
      })
    );

    return requests;
  } catch (error: any) {
    console.error("Error fetching pending received requests:", error);
    throw new Error(error.message);
  }
};

export const getAcceptedReceivedRequests = async (email: string) => {
  try {
    const userDocRef = doc(db, "users", email);
    const q = query(
      collection(userDocRef, "received_requests"),
      where("status", "==", "accepted")
    );
    const querySnapshot = await getDocs(q);

    const requests = await Promise.all(
      querySnapshot.docs.map(async (docSnapshot) => {
        const data = docSnapshot.data();
        const senderDoc = await getDoc(doc(db, "users", data.sender));
        const senderData = senderDoc.exists() ? senderDoc.data() : {};

        return {
          id: docSnapshot.id,
          ...data,
          senderInfo: senderData, // Add sender's additional information here
        };
      })
    );

    return requests;
  } catch (error: any) {
    console.error("Error fetching accepted received requests:", error);
    throw new Error(error.message);
  }
};

export const getRejectedReceivedRequests = async (email: string) => {
  try {
    const userDocRef = doc(db, "users", email);
    const q = query(
      collection(userDocRef, "received_requests"),
      where("status", "==", "rejected")
    );
    const querySnapshot = await getDocs(q);

    const requests = await Promise.all(
      querySnapshot.docs.map(async (docSnapshot) => {
        const data = docSnapshot.data();
        const senderDoc = await getDoc(doc(db, "users", data.sender));
        const senderData = senderDoc.exists() ? senderDoc.data() : {};

        return {
          id: docSnapshot.id,
          ...data,
          senderInfo: senderData, // Add sender's additional information here
        };
      })
    );

    return requests;
  } catch (error: any) {
    console.error("Error fetching rejected received requests:", error);
    throw new Error(error.message);
  }
};

export const cancelSendRequest = async (
  senderEmail: string,
  receiverEmail: string
) => {
  try {
    const db = getFirestore(app);

    // Find the request in the sender's send_requests collection
    const senderDocRef = doc(db, "users", senderEmail);
    const senderRequestsQuery = query(
      collection(senderDocRef, "send_requests"),
      where("receiver", "==", receiverEmail),
      where("status", "==", "pending")
    );
    const senderRequestsSnapshot = await getDocs(senderRequestsQuery);

    // Delete the request from the sender's send_requests collection
    senderRequestsSnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    // Find the request in the receiver's received_requests collection
    const receiverDocRef = doc(db, "users", receiverEmail);
    const receiverRequestsQuery = query(
      collection(receiverDocRef, "received_requests"),
      where("sender", "==", senderEmail),
      where("status", "==", "pending")
    );
    const receiverRequestsSnapshot = await getDocs(receiverRequestsQuery);

    // Delete the request from the receiver's received_requests collection
    receiverRequestsSnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });
  } catch (error: any) {
    console.error("Error canceling send request:", error);
    throw new Error(error.message);
  }
};

export const rejectReceiveRequest = async (
  receiverEmail: string,
  senderEmail: string
) => {
  try {
    const db = getFirestore(app);

    // Find the request in the receiver's received_requests collection
    const receiverDocRef = doc(db, "users", receiverEmail);
    const receiverRequestsQuery = query(
      collection(receiverDocRef, "received_requests"),
      where("sender", "==", senderEmail),
      where("status", "in", ["pending", "accepted"])
    );
    const receiverRequestsSnapshot = await getDocs(receiverRequestsQuery);

    // Update the status of the request to "rejected"
    receiverRequestsSnapshot.forEach(async (doc) => {
      await updateDoc(doc.ref, { status: "rejected" });
    });

    // Find the request in the sender's send_requests collection
    const senderDocRef = doc(db, "users", senderEmail);
    const senderRequestsQuery = query(
      collection(senderDocRef, "send_requests"),
      where("receiver", "==", receiverEmail),
      where("status", "in", ["pending", "accepted"])
    );
    const senderRequestsSnapshot = await getDocs(senderRequestsQuery);

    // Update the status of the request to "rejected"
    senderRequestsSnapshot.forEach(async (doc) => {
      await updateDoc(doc.ref, { status: "rejected" });
    });
  } catch (error: any) {
    console.error("Error rejecting received request:", error);
    throw new Error(error.message);
  }
};

export const acceptReceiveRequest = async (
  receiverEmail: string,
  senderEmail: string
) => {
  try {
    const db = getFirestore(app);

    // Find the request in the receiver's received_requests collection
    const receiverDocRef = doc(db, "users", receiverEmail);
    const receiverRequestsQuery = query(
      collection(receiverDocRef, "received_requests"),
      where("sender", "==", senderEmail),
      where("status", "==", "pending")
    );
    const receiverRequestsSnapshot = await getDocs(receiverRequestsQuery);

    // Update the status of the request to "accepted"
    receiverRequestsSnapshot.forEach(async (doc) => {
      await updateDoc(doc.ref, { status: "accepted" });
    });

    // Find the request in the sender's send_requests collection
    const senderDocRef = doc(db, "users", senderEmail);
    const senderRequestsQuery = query(
      collection(senderDocRef, "send_requests"),
      where("receiver", "==", receiverEmail),
      where("status", "==", "pending")
    );
    const senderRequestsSnapshot = await getDocs(senderRequestsQuery);

    // Update the status of the request to "accepted"
    senderRequestsSnapshot.forEach(async (doc) => {
      await updateDoc(doc.ref, { status: "accepted" });
    });
  } catch (error: any) {
    console.error("Error accepting received request:", error);
    throw new Error(error.message);
  }
};
