import React from "react";
import { whiteLogo } from "../../assets/images";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-primary_green">
      <div className="w-[100%] px-8 lg:px-[100px] py-8  flex flex-col lg:flex-row justify-between lg:items-start items-center font-DMSans">
        <div className="flex flex-col gap-4">
          <img src={whiteLogo} alt="" className="h-[74px]" />
        </div>

        <div className="flex flex-col gap-2 items-center lg:items-start py-8 lg:py-0">
          <h3 className="text-white text-[16px] font-bold">
            {t("quickLinks")}
          </h3>
          <Link to="/login" className="text-white text-[14px] font-normal">
            {t("publish")}
          </Link>
          <Link to="/login" className="text-white text-[14px] font-normal">
            {t("login")}
          </Link>
          <Link to="/register" className="text-white text-[14px] font-normal">
            {t("register")}
          </Link>
        </div>

        <div className="flex flex-col gap-2 items-center lg:items-start">
          <h3 className="text-white text-[16px] font-bold">{t("Other")}</h3>
          <p className="text-white text-[14px] font-normal">
            {t("Privacy Policy")}
          </p>
          <p className="text-white text-[14px] font-normal">
            {t("Terms and Conditions")}
          </p>
        </div>
      </div>
      <div className="text-center text-[12px] text-white font-normal font-DMSans pb-4">
        © 2024, All Rights Reserved
      </div>
    </div>
  );
};

export default Footer;
