import React from "react";
import { useTranslation } from "react-i18next";

interface TitleProps {
  title: string;
}

const TitleText: React.FC<TitleProps> = ({ title }) => {
  const { t } = useTranslation();
  return (
    <h3 className="text-[20px] lg:text-[28px] font-[600] text-custom_black">
      {t(title)}
    </h3>
  );
};

export default TitleText;
