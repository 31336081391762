export const gender = [{ label: "gender.male" }, { label: "gender.female" }];

export const race = [
  { label: "race.sinhalese" },
  { label: "race.tamil" },
  { label: "race.muslim" },
  { label: "race.burger" },
];

export const religion = [
  { label: "religion.buddhist" },
  { label: "religion.christian" },
  { label: "religion.hindu" },
  { label: "religion.muslim" },
];

export const caste = [
  { label: "caste.bathgama" },
  { label: "caste.choliya" },
  { label: "caste.colomboChetti" },
  { label: "caste.dewa" },
  { label: "caste.durawa" },
  { label: "caste.govigama" },
  { label: "caste.hunu" },
  { label: "caste.karava" },
  { label: "caste.kumbal" },
  { label: "caste.nekath" },
  { label: "caste.oli" },
  { label: "caste.panna" },
  { label: "caste.radala" },
  { label: "caste.rajaka" },
  { label: "caste.salagama" },
  { label: "caste.vishwa" },
  { label: "caste.other" },
];

export const age = [
  { label: "age.16-20" },
  { label: "age.21-25" },
  { label: "age.26-30" },
  { label: "age.31-35" },
  { label: "age.36-40" },
  { label: "age.41-45" },
  { label: "age.46-50" },
  { label: "age.51-55" },
  { label: "age.56-60" },
];

export const districts = [
  { label: "district.colombo" },
  { label: "district.gampaha" },
  { label: "district.kalutara" },
  { label: "district.kandy" },
  { label: "district.matale" },
  { label: "district.nuwaraEliya" },
  { label: "district.galle" },
  { label: "district.matara" },
  { label: "district.hambantota" },
  { label: "district.jaffna" },
  { label: "district.kilinochchi" },
  { label: "district.mannar" },
  { label: "district.vavuniya" },
  { label: "district.mullaitivu" },
  { label: "district.batticaloa" },
  { label: "district.ampara" },
  { label: "district.trincomalee" },
  { label: "district.kurunegala" },
  { label: "district.puttalam" },
  { label: "district.anuradhapura" },
  { label: "district.polonnaruwa" },
  { label: "district.badulla" },
  { label: "district.monaragala" },
  { label: "district.ratnapura" },
  { label: "district.kegalle" },
];

export const marriageOptions = [
  { label: "marriage.neverMarried" },
  { label: "marriage.limited" },
  { label: "marriage.limitedToSignature" },
];

export const education = [
  { label: "education.uptoOLs" },
  { label: "education.uptoALs" },
  { label: "education.diploma" },
  { label: "education.graduate" },
];

export const alcohol = [
  { label: "alcohol.no" },
  { label: "alcohol.occasionally" },
  { label: "alcohol.specialMoments" },
  { label: "alcohol.yes" },
];

export const job = [
  { label: "job.noJob" },
  { label: "job.ceo" },
  { label: "job.manager" },
  { label: "job.doctor" },
  { label: "job.engineer" },
  { label: "job.teacher" },
  { label: "job.nurse" },
  { label: "job.accountant" },
  { label: "job.forces" },
  { label: "job.businessman" },
  { label: "job.banker" },
  { label: "job.selfEmployee" },
  { label: "job.clark" },
  { label: "job.housewife" },
  { label: "job.labour" },
  { label: "job.other" },
];

export const incomeOptions = [
  { label: "income.below10000" },
  { label: "income.above10000" },
  { label: "income.above25000" },
  { label: "income.above50000" },
  { label: "income.above75000" },
  { label: "income.above100000" },
  { label: "income.above150000" },
  { label: "income.above200000" },
];

export const brothersOptions = [
  { label: "brothers.noBrothers" },
  { label: "brothers.oneBrother" },
  { label: "brothers.twoBrothers" },
  { label: "brothers.threeBrothers" },
  { label: "brothers.fourBrothers" },
  { label: "brothers.fiveBrothers" },
];

export const sistersOptions = [
  { label: "sisters.noSisters" },
  { label: "sisters.oneSister" },
  { label: "sisters.twoSisters" },
  { label: "sisters.threeSisters" },
  { label: "sisters.fourSisters" },
  { label: "sisters.fiveSisters" },
];

export const heightOptions = [
  { label: "height.4ft" },
  { label: "height.4ft1in" },
  { label: "height.4ft2in" },
  { label: "height.4ft3in" },
  { label: "height.4ft4in" },
  { label: "height.4ft5in" },
  { label: "height.4ft6in" },
  { label: "height.4ft7in" },
  { label: "height.4ft8in" },
  { label: "height.4ft9in" },
  { label: "height.4ft10in" },
  { label: "height.4ft11in" },
  { label: "height.5ft" },
  { label: "height.5ft1in" },
  { label: "height.5ft2in" },
  { label: "height.5ft3in" },
  { label: "height.5ft4in" },
  { label: "height.5ft5in" },
  { label: "height.5ft6in" },
  { label: "height.5ft7in" },
  { label: "height.5ft8in" },
  { label: "height.5ft9in" },
  { label: "height.5ft10in" },
  { label: "height.5ft11in" },
  { label: "height.6ft" },
  { label: "height.6ft1in" },
  { label: "height.6ft2in" },
  { label: "height.6ft3in" },
  { label: "height.6ft4in" },
  { label: "height.6ft5in" },
  { label: "height.6ft6in" },
  { label: "height.6ft7in" },
  { label: "height.6ft8in" },
  { label: "height.6ft9in" },
  { label: "height.6ft10in" },
  { label: "height.6ft11in" },
  { label: "height.7ft" },
];

export const weightOptions = [
  { label: "weight.30kg" },
  { label: "weight.31kg" },
  { label: "weight.32kg" },
  { label: "weight.33kg" },
  { label: "weight.34kg" },
  { label: "weight.35kg" },
  { label: "weight.36kg" },
  { label: "weight.37kg" },
  { label: "weight.38kg" },
  { label: "weight.39kg" },
  { label: "weight.40kg" },
  { label: "weight.41kg" },
  { label: "weight.42kg" },
  { label: "weight.43kg" },
  { label: "weight.44kg" },
  { label: "weight.45kg" },
  { label: "weight.46kg" },
  { label: "weight.47kg" },
  { label: "weight.48kg" },
  { label: "weight.49kg" },
  { label: "weight.50kg" },
  { label: "weight.51kg" },
  { label: "weight.52kg" },
  { label: "weight.53kg" },
  { label: "weight.54kg" },
  { label: "weight.55kg" },
  { label: "weight.56kg" },
  { label: "weight.57kg" },
  { label: "weight.58kg" },
  { label: "weight.59kg" },
  { label: "weight.60kg" },
  { label: "weight.61kg" },
  { label: "weight.62kg" },
  { label: "weight.63kg" },
  { label: "weight.64kg" },
  { label: "weight.65kg" },
  { label: "weight.66kg" },
  { label: "weight.67kg" },
  { label: "weight.68kg" },
  { label: "weight.69kg" },
  { label: "weight.70kg" },
  { label: "weight.71kg" },
  { label: "weight.72kg" },
  { label: "weight.73kg" },
  { label: "weight.74kg" },
  { label: "weight.75kg" },
  { label: "weight.76kg" },
  { label: "weight.77kg" },
  { label: "weight.78kg" },
  { label: "weight.79kg" },
  { label: "weight.80kg" },
  { label: "weight.81kg" },
  { label: "weight.82kg" },
  { label: "weight.83kg" },
  { label: "weight.84kg" },
  { label: "weight.85kg" },
  { label: "weight.86kg" },
  { label: "weight.87kg" },
  { label: "weight.88kg" },
  { label: "weight.89kg" },
  { label: "weight.90kg" },
  { label: "weight.91kg" },
  { label: "weight.92kg" },
  { label: "weight.93kg" },
  { label: "weight.94kg" },
  { label: "weight.95kg" },
  { label: "weight.96kg" },
  { label: "weight.97kg" },
  { label: "weight.98kg" },
  { label: "weight.99kg" },
  { label: "weight.100kg" },
];

// export const assetsOptions = [
//   { label: "Cash 1 lak -5 lak" },
//   { label: "Cash 5 lak -1 milion" },
//   { label: "Cash 1 milion -5 milion" },
//   { label: "Cash above 5 milion" },
//   { label: "Block of Land" },
//   { label: "Seperate House" },
//   { label: "Parental House" },
//   { label: "Commercial Building" },
//   { label: "A vehicle" },
//   { label: "Jeweleries" },
//   { label: "Furniture" },
//   { label: "Available but not to mention" },
// ];
