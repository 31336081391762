import React, { useState } from "react";
import { CustomButton, CustomInput, Footer, Navbar } from "../../components";
import { loginUser } from "../../services/auth/authService";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { message } from "antd";

const Login = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (e.target.value === "") {
      setError("This field is required");
    } else {
      setError("");
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value === "") {
      setError("This field is required");
    } else {
      setError("");
    }
  };

  const handleLogin = async () => {
    setError("");
    setLoading(true);

    try {
      await loginUser(email, password);
      message.success("Login successful");
      navigate("/user/proposals");
    } catch (err: any) {
      message.error("Invalid email or password");
      // console.error("Login error", err);
      // setError(err.message || "Login failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar isLoggedIn={false} />

      <div className="flex-grow">
        {/* Login */}
        <div className="px-8 lg:px-[100px] py-8 flex items-center justify-center font-DMSans">
          <div
            className="bg-white p-4 flex flex-col  rounded-lg w-full lg:w-[400px] px-4 lg:px-12 py-8"
            style={{
              boxShadow: "0.1px 0.1px 2px #AFAFAF",
            }}
          >
            <h3 className="text-[18px] font-medium text-custom_black text-center pb-2">
              {t("login")}
            </h3>
            <div className="flex flex-col justify-start items-start w-full gap-2">
              <CustomInput
                label="Email address"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
                errorMessage={error}
              />
            </div>
            <div className="flex flex-col justify-start items-start w-full gap-2">
              <CustomInput
                placeholder="Enter your password"
                label="Password"
                value={password}
                onChange={handlePasswordChange}
                errorMessage={error}
                type="password"
              />
            </div>
            <p className="text-[12px] text-primary_green font-[400] text-right pb-4 cursor-pointer">
              {t("Forgot your password ?")}
            </p>
            <CustomButton
              text={loading ? "Loading... " : "login"}
              onClick={handleLogin}
            />
            <div className="flex flex-row justify-center items-center mt-4 gap-2 lg:hidden">
              <p className="text-[12px] text-gray-600 font-[400] text-right pb-4 cursor-pointer">
                {t("Don't have an account ?")}
              </p>
              <Link
                className="text-[12px] text-primary_green font-[400] text-right pb-4 cursor-pointer"
                to="/register"
              >
                {t("register")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
