import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { whiteLogo } from "../../assets/images";
import CustomButton from "../buttons/CustomButton";
import NavbarText from "../texts/Navbartext";
import {
  IconLogin,
  IconLogout,
  IconMenu2,
  IconReservedLine,
  IconSend,
  IconSettings,
  IconSmartHome,
  IconTrashFilled,
  IconUser,
  IconUserPlus,
} from "@tabler/icons-react";
import MobileMenu from "./MobileMenu";
import { deleteAccount, logoutUser } from "../../services/auth/authService";
import LanguageSelector from "../../services/LanguageSelector";
import { useTranslation } from "react-i18next";

interface NavbarProps {
  isLoggedIn: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ isLoggedIn }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [deleteError, setDeleteError] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const toggleSettings = () => {
    setIsSettingsVisible(!isSettingsVisible);
  };

  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
  };

  const handleLogoutConfirm = () => {
    setIsLogoutModalOpen(false);
    handleLogout();
  };

  const handleLogoutCancel = () => {
    setIsLogoutModalOpen(false);
  };

  const handleDeleteAccount = async () => {
    if (!deleteReason) {
      setDeleteError("Please select a reason for deleting your account.");
      return;
    }

    try {
      await deleteAccount();
      console.log("Account deleted successfully");
      navigate("/");
    } catch (error: any) {
      console.log("Failed to delete account: ", error.message);
    }
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      console.log("Logout successfully");
      navigate("/");
    } catch (error: any) {
      console.log("Failed to logut: ", error.message);
    }
  };

  return (
    <nav className="w-[100%] bg-primary_green h-[60px] lg:h-[80px] px-8 lg:px-[100px] flex flex-row items-center justify-between  font-DMSans">
      {/* Desktop navbar */}
      <div className="hidden w-full lg:flex justify-between items-center max-w-[1920px] mx-auto">
        <Link to="/" className="flex items-center gap-2 h-[74px]">
          <img src={whiteLogo} alt="" className="h-[60px]" />
        </Link>
        <div className="flex flex-row justify-center items-center gap-4">
          {!isLoggedIn ? (
            <>
              <CustomButton
                text="publish"
                link="/login"
                border="1px solid #FFAD30"
                textColor="#FFAD30"
              />
              <CustomButton
                text="login"
                color="#FFAD30"
                textColor="#333333"
                border="1px solid #009A74"
                link="/login"
                icon={<IconLogin size={16} />}
              />
              <CustomButton
                text="register"
                color="#FFAD30"
                textColor="#333333"
                border="1px solid #009A74"
                link="/register"
                icon={<IconUserPlus size={16} />}
              />
            </>
          ) : (
            <>
              <NavbarText
                icon={<IconSmartHome size={16} />}
                text="proposals"
                link="/user/proposals"
              />
              <NavbarText
                icon={<IconSend size={16} />}
                text="sendRequests"
                link="/user/send-requests"
              />
              <NavbarText
                icon={<IconReservedLine size={16} />}
                text="receiveRequests"
                link="/user/receive-requests"
              />
              <NavbarText
                icon={<IconUser size={16} />}
                text="profile"
                link="/user/profile"
              />
              <NavbarText
                icon={<IconSettings size={16} />}
                text="settings"
                onClick={toggleSettings}
              />
              {isSettingsVisible && (
                <div className="absolute top-16 right-8 bg-white shadow-lg rounded-lg p-4 flex flex-col items-start font-DMSans gap-2">
                  <button
                    className="flex items-center gap-2 text-red-800"
                    onClick={handleLogoutClick}
                  >
                    <IconLogout size={16} className="text-red-800" />
                    <span className="text-[14px]">{t("logout")}</span>
                  </button>
                  <button
                    className="flex items-center gap-2 text-zinc-600"
                    onClick={() => setIsDeleteModalOpen(true)}
                  >
                    <IconTrashFilled size={16} className="text-zinc-600" />
                    <span className="text-[14px]">{t("deleteAcc")}</span>
                  </button>
                </div>
              )}

              {isDeleteModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center font-DMSans">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-[300px]">
                    <h2 className="text-[18px] font-semibold mb-2">
                      {t("deleteAcc")}
                    </h2>
                    <p className="mb-4 text-gray-500">
                      {t("Are you sure you want to delete your account?")}
                    </p>
                    <p className="mb-4 text-gray-500">
                      {t("Please select a reason")}:
                    </p>
                    <select
                      className="w-full mb-4 p-2 border rounded "
                      value={deleteReason}
                      onChange={(e) => setDeleteReason(e.target.value)}
                    >
                      <option value="">{t("Select a reason")}</option>
                      <option value="Privacy concerns">
                        {t("Privacy concerns")}
                      </option>
                      <option value="Not finding matches">
                        {t("Not finding matches")}
                      </option>
                      <option value="Found a partner">
                        {t("Found a partner")}
                      </option>
                      <option value="Other">{t("Other")}</option>
                    </select>
                    {deleteError && (
                      <p className="text-red-500 mb-4">{deleteError}</p>
                    )}
                    <div className="flex justify-end gap-4">
                      <CustomButton
                        text="cancel"
                        color="rgb(229 231 235)"
                        textColor="black"
                        onClick={() => setIsDeleteModalOpen(false)}
                      />

                      <CustomButton
                        text="delete"
                        color=" rgb(220 38 38)"
                        textColor="white"
                        onClick={handleDeleteAccount}
                      />
                    </div>
                  </div>
                </div>
              )}

              {isLogoutModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center font-DMSans">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-[300px]">
                    <h2 className="text-[18px] font-semibold mb-2">
                      {t("logout")}
                    </h2>
                    <p className="mb-4 text-gray-500">
                      {t("Are you sure you want to logout?")}
                    </p>
                    <div className="flex justify-end gap-4">
                      <CustomButton
                        text="cancel"
                        color="rgb(229 231 235)"
                        textColor="black"
                        onClick={handleLogoutCancel}
                      />

                      <CustomButton
                        text="logout"
                        color=" rgb(220 38 38)"
                        textColor="white"
                        onClick={handleLogoutConfirm}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <LanguageSelector />
        </div>
      </div>

      {/* Hamburger menu */}
      <div className="lg:hidden flex w-full justify-between items-center">
        <Link to="/" className="flex items-center gap-2">
          <img src={whiteLogo} alt="" className="h-[50px]" />
        </Link>
        <div className="flex flex-row justify-center items-center gap-4">
          {/* Language Selector for mobile view */}
          <LanguageSelector />

          {!isLoggedIn ? (
            <>
              <IconUser
                size={24}
                className="text-white"
                onClick={togglePopup}
              />
              {isPopupVisible && (
                <div className="absolute top-16 right-8 bg-white shadow-lg rounded-lg p-4 flex flex-col items-start gap-4">
                  <NavbarText
                    icon={<IconLogin size={16} />}
                    text="login"
                    link="/login"
                    textColor="#333333"
                  />
                  <NavbarText
                    icon={<IconUserPlus size={16} />}
                    text="register"
                    link="/register"
                    textColor="#333333"
                  />
                </div>
              )}
            </>
          ) : (
            <div className="flex items-center">
              <button onClick={toggleMobileMenu}>
                <IconMenu2 size={20} className="text-white" />
              </button>
            </div>
          )}
        </div>
      </div>

      <MobileMenu
        isMobileMenuOpen={isMobileMenuOpen}
        toggleMobileMenu={toggleMobileMenu}
      />
    </nav>
  );
};

export default Navbar;
