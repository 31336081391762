import React from "react";
import { useTranslation } from "react-i18next";

interface ParProps {
  description: string;
}

const ParagraphText: React.FC<ParProps> = ({ description }) => {
  const { t } = useTranslation();
  return (
    <p className="text-[12px] lg:text-[16px] font-light text-[#8B8B8B] text-center">
      {t(description)}
    </p>
  );
};

export default ParagraphText;
