import React, { useEffect, useState } from "react";
import { Navbar, TitleText, ParagraphText } from "../../components";
import BottomNavBar from "../../components/navbar/BottomNavbar";
import { RequestData } from "../send_requests/SendRequests";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getAcceptedReceivedRequests,
  getPendingReceivedRequests,
  getRejectedReceivedRequests,
} from "../../services/send_requests/sendRequests";
import { useTranslation } from "react-i18next";
import ReceiveRequestCard from "../../components/receiveRequests/ReceiveRequestCard";
import { Commet } from "react-loading-indicators";
import NoPostsAvailable from "../../components/loaders/NoPost";

const ReceiveRequests = () => {
  const [requests, setRequests] = useState<RequestData[]>([]);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [requestsLoading, setRequestsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email);
      } else {
        setUserEmail(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchRequests = async () => {
      setRequestsLoading(true);
      if (userEmail) {
        try {
          const pendingRequests = await getPendingReceivedRequests(userEmail);
          const acceptedRequests = await getAcceptedReceivedRequests(userEmail);
          const rejectedRequests = await getRejectedReceivedRequests(userEmail);

          const combinedRequests = [
            ...pendingRequests.map((request) => ({
              ...request,
              status: "pending" as "pending",
            })),
            ...acceptedRequests.map((request) => ({
              ...request,
              status: "accepted" as "accepted",
            })),
            ...rejectedRequests.map((request) => ({
              ...request,
              status: "rejected" as "rejected",
            })),
          ];

          setRequests(
            combinedRequests.map((request) => ({
              id: request.id,
              profilePicture: request.senderInfo?.profilePicture || "",
              name: request.senderInfo?.name || "_",

              day: request.senderInfo?.day || "_",
              month: request.senderInfo?.month || "_",
              year: request.senderInfo?.year || "_",
              location: request.senderInfo?.district || "_",
              job: request.senderInfo?.job || "_",
              height: request.senderInfo?.height || "_",
              weight: request.senderInfo?.weight || "_",
              race: request.senderInfo?.race || "_",
              caste: request.senderInfo?.caste || "_",
              religion: request.senderInfo?.religion || "_",
              education: request.senderInfo?.education || "_",
              income: request.senderInfo?.income || "_",
              marriage: request.senderInfo?.marriage || "_",
              alcohol: request.senderInfo?.alcohol || "_",
              brothers: request.senderInfo?.brothers,
              sisters: request.senderInfo?.sisters,
              gender: request.senderInfo?.gender || "_",
              email: request.senderInfo?.email || "_",
              status: request.status,
              otherDetails: request.senderInfo?.otherDetails || "_",
            }))
          );
        } catch (error) {
          console.error("Error fetching accepted requests:", error);
        } finally {
          setRequestsLoading(false);
        }
      }
    };
    fetchRequests();
  }, [userEmail]);

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar isLoggedIn={true} />
      <div className="flex-grow">
        <div className="px-8 lg:px-[100px] py-8 flex flex-col items-center">
          <div className="flex flex-col justify-center items-center gap-2">
            <TitleText title={t("receiveRequests")} />
            <ParagraphText description={t("receiveText")} />
          </div>
          <div className="flex flex-wrap justify-center gap-4 lg:gap-8 px-10 py-6">
            {requestsLoading ? (
              <Commet
                color="#009A74"
                size="medium"
                text=""
                textColor="#009A74"
              />
            ) : requests.length === 0 ? (
              <NoPostsAvailable />
            ) : (
              requests.map((request) => (
                <ReceiveRequestCard
                  key={request.id}
                  photo={request.profilePicture || ""}
                  name={request.name || ""}
                  day={request.day || ""}
                  month={request.month || ""}
                  year={request.year || ""}
                  district={request.location || ""}
                  job={request.job || ""}
                  height={request.height || ""}
                  weight={request.weight || ""}
                  race={request.race || ""}
                  caste={request.caste || ""}
                  religion={request.religion || ""}
                  education={request.education || ""}
                  income={request.income || ""}
                  marriage={request.marriage || ""}
                  alcohol={request.alcohol || ""}
                  sisters={(request.sisters ?? "").toString()}
                  brothers={(request.brothers ?? "").toString()}
                  gender={request.gender || ""}
                  email={request.email || ""}
                  status={request.status || "pending"}
                  mobile={request.mobile || ""}
                  otherDetails={request.otherDetails || ""}
                />
              ))
            )}
          </div>
        </div>
      </div>
      <BottomNavBar />
    </div>
  );
};

export default ReceiveRequests;
