import React, { useState } from "react";
import { CustomButton, CustomInput, Footer, Navbar } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../../services/auth/authService";
import { useTranslation } from "react-i18next";
import { message } from "antd";

const Register = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(""); // Reset error message on change
  };

  const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMobile(e.target.value);
    setMobileError("");
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const validateFields = () => {
    let isValid = true;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Please enter your email address.");
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!mobile) {
      setMobileError("Please enter your mobile number.");
      isValid = false;
    } else if (!/^\d{10}$/.test(mobile)) {
      setMobileError("Mobile number should be exactly 10 digits.");
      isValid = false;
    } else {
      setMobileError("");
    }

    if (!password) {
      setPasswordError("Please enter your password.");
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError("Password should be at least 6 characters.");
      isValid = false;
    } else {
      setPasswordError("");
    }

    return isValid;
  };

  const handleRegister = async () => {
    if (!validateFields()) return;

    setLoading(true);

    try {
      await registerUser(email, password, mobile);
      message.success("Registration successful");
      navigate("/register/personal-details");
    } catch (err: any) {
      message.error("Registration failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar isLoggedIn={false} />
      <div className="flex-grow">
        <div className="px-8 lg:px-[100px] py-8 flex items-center justify-center font-DMSans">
          <div
            className="bg-white p-4 flex flex-col  rounded-lg w-full lg:w-[400px] px-4 lg:px-12 py-8"
            style={{
              boxShadow: "0.1px 0.1px 2px #AFAFAF",
            }}
          >
            <h3 className="text-[18px] font-medium text-custom_black text-center pb-4">
              {t("register")}
            </h3>
            <div className="flex flex-col justify-start items-start w-full gap-2">
              <CustomInput
                label="Email address"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
                errorMessage={emailError}
              />
            </div>
            <div className="flex flex-col justify-start items-start w-full gap-2">
              <CustomInput
                label="Mobile number"
                placeholder="Enter your mobile number"
                value={mobile}
                onChange={handleMobileChange}
                errorMessage={mobileError}
              />
            </div>
            <div className="flex flex-col justify-start items-start w-full gap-2 pb-4">
              <CustomInput
                label="Password"
                placeholder="Enter your password"
                value={password}
                onChange={handlePasswordChange}
                errorMessage={passwordError}
                type="password"
              />
            </div>

            <CustomButton
              text={loading ? "Loading..." : "register"}
              onClick={handleRegister}
            />
            <div className="flex flex-row justify-center items-center mt-4 gap-2 lg:hidden">
              <p className="text-[12px] text-gray-600 font-[400] text-right pb-4 cursor-pointer">
                {t("I already have an account")}
              </p>
              <Link
                className="text-[12px] text-primary_green font-[400] text-right pb-4 cursor-pointer"
                to="/login"
              >
                {t("login")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
