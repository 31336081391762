import React, { useState } from "react";
import { menAvatar, womenAvatar } from "../../assets/images";
import CustomButton from "../buttons/CustomButton";
import { IconPhone } from "@tabler/icons-react";
import SendRequestModal from "./SendRequestModal";
import { useTranslation } from "react-i18next";
import { calculateAge } from "../../utils/utils";

interface SendRequestCardProps {
  photo: string;
  name: string;
  day: string;
  month: string;
  year: string;
  district: string;
  job: string;
  height: string;
  weight: string;
  race: string;
  caste: string;
  religion: string;
  education: string;
  income: string;
  marriage: string;
  alcohol: string;
  brothers: string;
  sisters: string;
  gender: string;
  email: string;
  status: "pending" | "accepted" | "rejected";
  mobile: string;
  otherDetails: string;
}

const SendRequestCard: React.FC<SendRequestCardProps> = ({
  photo,
  name,
  day,
  month,
  year,
  district,
  job,
  height,
  weight,
  race,
  caste,
  religion,
  education,
  income,
  marriage,
  alcohol,
  brothers,
  sisters,
  gender,
  email,
  status,
  mobile,
  otherDetails,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleCardClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const defaultAvatar = gender === "Male" ? menAvatar : womenAvatar;
  const age = calculateAge(year, month, day);

  const statusStyles = {
    pending: {
      text: "Pending",
      borderColor: "border-golden",
      textColor: "text-golden",
    },
    accepted: {
      text: "Accepted",
      borderColor: "border-primary_green",
      textColor: "text-primary_green",
    },
    rejected: {
      text: "Rejected",
      borderColor: "border-red-500",
      textColor: "text-red-500",
    },
  };

  const { text, borderColor, textColor } = statusStyles[status];

  return (
    <>
      <div
        className={`bg-white p-4 flex flex-col items-center gap-2 rounded-lg min-w-[310px] border ${borderColor} cursor-pointer`}
        style={{
          boxShadow: "1px 1px 5px #AFAFAF",
        }}
        onClick={handleCardClick}
      >
        <div className="flex flex-row items-center gap-4 w-full">
          <img
            src={photo || defaultAvatar}
            alt={name}
            className="w-20 h-20 object-cover rounded-lg border"
          />
          <div className="flex flex-col w-[180px] justify-center">
            <h3 className="text-[16px] font-medium text-custom_black pb-2">
              {name}
            </h3>

            <div className="flex flex-row justify-between items-center pb-1">
              <p className="text-[12px] font-[400] text-gray-600">
                {age} {t("years")}
              </p>
              <p className="text-[12px] font-[400] text-gray-600">
                {t(district)}
              </p>
            </div>

            <div className="flex flex-row justify-between items-center">
              <p className="text-[12px] font-[400] text-gray-600">{t(job)}</p>
              {height && (
                <p className="text-[12px] font-[400] text-gray-600">
                  {t(`${height}`)}
                </p>
              )}
            </div>
          </div>
        </div>
        <div
          className={`w-full py-0.5 bg-transparent border ${borderColor} rounded-full`}
        >
          <div className={`text-xs font-normal ${textColor} text-center`}>
            {text}
          </div>
        </div>
        {status === "accepted" && (
          <div className="w-full">
            <CustomButton
              text="Get phone number"
              width="100%"
              border="1px solid #009a4a"
              icon={<IconPhone size={16} />}
            />
          </div>
        )}
      </div>

      <SendRequestModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        user={{
          photo,
          name,
          age,
          district,
          job,
          height,
          weight,
          race,
          caste,
          religion,
          education,
          income,
          marriage,
          alcohol,
          brothers,
          sisters,
          gender,
          email,
          status,
          mobile,
          otherDetails,
        }}
      />
    </>
  );
};

export default SendRequestCard;
