import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="flex flex-row gap-2 p-1 bg-golden rounded-md">
      <button
        className={`text-[12px] font-bold px-2 py-1 rounded-md transition-all ${
          i18n.language === "en"
            ? "bg-primary_green text-white"
            : "bg-white text-custom_black"
        }`}
        onClick={() => changeLanguage("en")}
      >
        En
      </button>
      <button
        className={`text-[12px] font-bold px-2 py-1 rounded-md transition-all ${
          i18n.language === "si"
            ? "bg-primary_green text-white"
            : "bg-white text-custom_black"
        }`}
        onClick={() => changeLanguage("si")}
      >
        සිං
      </button>
    </div>
  );
};

export default LanguageSelector;
