import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ButtonProps {
  text: string;
  color?: string;
  textColor?: string;
  border?: string;
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  iconSize?: string;
  onClick?: () => void;
  link?: string;
  width?: string;
  disabled?: boolean;
}

const CustomButton: React.FC<ButtonProps> = ({
  text,
  color = "#009A74",
  textColor = "white",
  border = "none",
  icon,
  rightIcon,
  iconSize = "14px",
  onClick,
  link,
  width,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const buttonContent = (
    <button
      disabled={disabled}
      onClick={onClick}
      className="px-2 py-1 lg:px-4 lg:py-2 flex justify-center "
      style={{
        backgroundColor: color,
        color: textColor,
        border: border,
        fontSize: "14px",
        fontWeight: "400",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        cursor: "pointer",
        borderRadius: "8px",
        textAlign: "center",
        width: width,
      }}
    >
      {icon && <span style={{ fontSize: iconSize }}>{icon}</span>}
      {t(text)}
      {rightIcon && <span style={{ fontSize: iconSize }}>{rightIcon}</span>}
    </button>
  );

  // If link prop is provided, wrap the button in Link; otherwise, render the button as is
  return link ? (
    <Link to={link} style={{ textDecoration: "none" }}>
      {buttonContent}
    </Link>
  ) : (
    buttonContent
  );
};

export default CustomButton;
