import React, { useState, useEffect } from "react";
import { IconPhone, IconX } from "@tabler/icons-react"; // Import the close icon
import { menAvatar, womenAvatar } from "../../assets/images";
import CustomButton from "../buttons/CustomButton";
import { useAuth } from "../../contexts/authContexts";
import {
  rejectReceiveRequest,
  acceptReceiveRequest,
} from "../../services/send_requests/sendRequests";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { message, Modal } from "antd";

interface ReceiveRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: {
    photo: string;
    name: string;
    age: number;
    district: string;
    job: string;
    height: string;
    weight: string;
    race: string;
    caste: string;
    religion: string;
    education: string;
    income: string;
    marriage: string;
    alcohol: string;
    brothers: string;
    sisters: string;
    gender: string;
    email?: string; // Make email optional
    status: "pending" | "accepted" | "rejected";
    mobile: string;
    otherDetails: string;
  };
}

const ReceiveRequestModal: React.FC<ReceiveRequestModalProps> = ({
  isOpen,
  onClose,
  user,
}) => {
  const { currentUser } = useAuth();
  const [phoneNumbers, setPhoneNumbers] = useState<{
    sender: string;
    receiver: string;
  } | null>(null);
  const [isPhoneModalVisible, setIsPhoneModalVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!user.email) {
      console.error("Sender email is missing.");
    }
  }, [user]);

  if (!isOpen) return null;

  const defaultAvatar = user.gender === "Male" ? menAvatar : womenAvatar;

  const handleReject = async () => {
    if (user.email && currentUser?.email) {
      try {
        await rejectReceiveRequest(currentUser.email, user.email);
        message.success("Request rejected successfully.");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        message.error("Error rejecting request.");
      }
    } else {
      console.error("Sender or current user email is missing.");
    }
  };

  const handleAccept = async () => {
    if (user.email && currentUser?.email) {
      try {
        await acceptReceiveRequest(currentUser.email, user.email);
        message.success("Request accepted successfully.");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        message.error("Error accepting request.");
      }
    } else {
      console.error("Sender or current user email is missing.");
    }
  };

  const handleGetPhoneNumber = async () => {
    try {
      if (currentUser?.email && user.email) {
        const db = getFirestore();
        const senderDocRef = doc(db, "users", currentUser.email);
        const receiverDocRef = doc(db, "users", user.email);

        const senderDoc = await getDoc(senderDocRef);
        const receiverDoc = await getDoc(receiverDocRef);

        if (senderDoc.exists() && receiverDoc.exists()) {
          setPhoneNumbers({
            sender: senderDoc.data().mobile,
            receiver: receiverDoc.data().mobile,
          });
          setIsPhoneModalVisible(true);
        } else {
          console.error("Sender or receiver document does not exist.");
        }
      } else {
        console.error("Current user email or receiver email is undefined.");
      }
    } catch (error) {
      console.error("Error fetching phone numbers:", error);
    }
  };

  const handlePhoneModalClose = () => {
    setIsPhoneModalVisible(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-white p-8 rounded-lg shadow-lg w-[90%] max-w-[600px]">
        <button
          className="absolute top-4 right-4 text-gray-600"
          onClick={onClose}
        >
          <IconX size={24} />
        </button>
        <div className="flex flex-col items-center">
          <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-start  lg:gap-4 gap-1 w-full max-w-[400px]">
            <img
              src={user.photo || defaultAvatar}
              alt={user.name}
              className="w-32 h-32 object-cover rounded-lg border border-golden mb-4"
            />
            <div className="flex-col flex justify-center items-center lg:items-start w-full text-center lg:text-left">
              <div className="text-[12px] text-gray-600 block lg:hidden">
                {user.status === "pending" && "You have a pending request"}
                {user.status === "accepted" && "You accepted the request"}
                {user.status === "rejected" && "You rejected the request"}
              </div>
              {user.status === "pending" && (
                <div className="flex gap-2 mt-2 lg:hidden flex-row justify-center items-center my-4">
                  <CustomButton
                    text="Reject"
                    border="1px solid #009A74"
                    textColor="#009A74"
                    color="white"
                    width="150px"
                    onClick={handleReject}
                  />
                  <CustomButton
                    text="Accept"
                    width="150px"
                    border="1px solid #009a4a"
                    onClick={handleAccept}
                  />
                </div>
              )}
              {user.status === "accepted" && (
                <div className="flex-col lg:flex-row gap-2 my-4 flex justify-center items-center lg:hidden">
                  <CustomButton
                    text="Reject"
                    border="1px solid #009A74"
                    textColor="#009A74"
                    color="white"
                    width="150px"
                    onClick={handleReject}
                  />
                  <CustomButton
                    text="Get Phone number"
                    icon={<IconPhone size={16} />}
                    border="1px solid #009a4a"
                    onClick={handleGetPhoneNumber}
                  />
                </div>
              )}
              <h2 className="lg:text-[20px] text-[18px] font-semibold text-custom_black mb-1">
                {user.name}
              </h2>

              <UserDetailRow label={t("details.age")} value={`${user.age}`} />
              <UserDetailRow
                label={t("details.location")}
                value={t(user.district)}
              />

              <UserDetailRow label={t("Job")} value={t(user.job)} />
            </div>
          </div>
          <div className="text-[12px] text-gray-600 lg:block hidden">
            {user.status === "pending" && "You have a pending request"}
            {user.status === "accepted" && "You accepted the request"}
            {user.status === "rejected" && "You rejected the request"}
          </div>
          {user.status === "pending" && (
            <div className=" gap-2 mt-2 lg:block hidden">
              <CustomButton
                text="Reject"
                border="1px solid #009A74"
                textColor="#009A74"
                color="white"
                width="150px"
                onClick={handleReject}
              />
              <CustomButton
                text="Accept"
                width="150px"
                border="1px solid #009a4a"
                onClick={handleAccept}
              />
            </div>
          )}
          {user.status === "accepted" && (
            <div className="flex-row gap-2 my-4 lg:flex hidden">
              <CustomButton
                text="Reject"
                border="1px solid #009A74"
                textColor="#009A74"
                color="white"
                width="150px"
                onClick={handleReject}
              />
              <CustomButton
                text="Get Phone number"
                icon={<IconPhone size={16} />}
                border="1px solid #009a4a"
                onClick={handleGetPhoneNumber}
              />
            </div>
          )}
          <div className="flex-col flex justify-between items-center mb-2 w-full max-w-[400px]">
            <UserDetailRow label={t("details.height")} value={t(user.height)} />

            <UserDetailRow label={t("details.weight")} value={t(user.weight)} />

            <UserDetailRow label={t("Race")} value={t(user.race)} />

            <UserDetailRow label={t("Caste")} value={t(user.caste)} />

            <UserDetailRow label={t("Religion")} value={t(user.religion)} />

            <UserDetailRow
              label={t("Education status")}
              value={t(user.education)}
            />

            <UserDetailRow label={t("Monthly income")} value={t(user.income)} />

            <UserDetailRow
              label={t("Marriage status")}
              value={t(user.marriage)}
            />

            <UserDetailRow
              label={t("details.alcohol")}
              value={t(user.alcohol)}
            />

            <UserDetailRow
              label={t("details.brothers")}
              value={t(user.brothers)}
            />
            <UserDetailRow
              label={t("details.sisters")}
              value={t(user.sisters)}
            />
            <div className="flex flex-row justify-between items-center mb-1 w-full ">
              <p className="text-custom_black font-[500] text-[14px]">
                <span className="text-gray-600">Other details: </span>
                {user.otherDetails}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Phone Numbers"
        visible={isPhoneModalVisible}
        onCancel={handlePhoneModalClose}
        footer={null}
      >
        <div className="flex items-center mb-4">
          <img
            src={
              currentUser?.photoURL ||
              (currentUser?.gender === "Male" ? menAvatar : womenAvatar)
            }
            alt="Sender"
            className="w-12 h-12 rounded-full mr-4"
          />
          <p>Your Phone Number: {phoneNumbers?.sender}</p>
        </div>
        <div className="flex items-center">
          <img
            src={
              user.photo || (user.gender === "Male" ? menAvatar : womenAvatar)
            }
            alt="Receiver"
            className="w-12 h-12 rounded-full mr-4"
          />
          <p>Receiver's Phone Number: {phoneNumbers?.receiver}</p>
        </div>
      </Modal>
    </div>
  );
};

interface UserDetailRowProps {
  label: string;
  value: string | number;
}

const UserDetailRow: React.FC<UserDetailRowProps> = ({ label, value }) => {
  return (
    <div className="flex flex-row justify-between items-center mb-1 w-full">
      <p className="text-gray-600 font-normal text-[14px]">{label} :</p>
      <p className="text-custom_black font-[500] text-[14px]">{value}</p>
    </div>
  );
};

export default ReceiveRequestModal;
