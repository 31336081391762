import React from "react";
import { noProposal } from "../../assets/images";

const NoPostsAvailable: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center py-10">
      <img src={noProposal} alt="No posts" className="w-40 h-40 mb-4" />
      <h2 className="text-[14px] font-[600] text-gray-600 mb-2">
        No Proposals Available
      </h2>
      <p className="text-gray-500 text-center text-[12px]">
        It looks like there are no proposals available at the moment. Please
        check back later or try adjusting your search criteria.
      </p>
    </div>
  );
};

export default NoPostsAvailable;
