import React from "react";
import {
  IconSend,
  IconUser,
  IconSmartHome,
  IconReservedLine,
} from "@tabler/icons-react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation hook
import { useTranslation } from "react-i18next";

const BottomNavBar: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();

  // A function to check if the current path matches the link path
  const isActive = (path: string) => location.pathname === path;

  return (
    <div className="fixed bottom-0 w-full bg-gray-50 shadow-lg  lg:hidden border-t">
      <div className="flex justify-between items-center px-6 py-2">
        {/* Proposals Link */}
        <Link to="/user/proposals" className="flex flex-col items-center">
          <IconSmartHome
            size={20}
            className={
              isActive("/user/proposals")
                ? "text-primary_green"
                : "text-custom_black"
            } // Conditionally apply green color
          />
          <span
            className={`text-[14px] font-normal ${
              isActive("/user/proposals")
                ? "text-primary_green"
                : "text-custom_black"
            }`}
          >
            {t("proposals")}
          </span>
        </Link>

        {/* Send Requests Link */}
        <Link to="/user/send-requests" className="flex flex-col items-center">
          <IconSend
            size={20}
            className={
              isActive("/user/send-requests")
                ? "text-primary_green"
                : "text-custom_black"
            } // Conditionally apply green color
          />
          <span
            className={`text-[14px] font-normal ${
              isActive("/user/send-requests")
                ? "text-primary_green"
                : "text-custom_black"
            }`}
          >
            {t("send")}
          </span>
        </Link>

        {/* Receive Requests Link */}
        <Link
          to="/user/receive-requests"
          className="flex flex-col items-center"
        >
          <IconReservedLine
            size={20}
            className={
              isActive("/user/receive-requests")
                ? "text-primary_green"
                : "text-custom_black"
            } // Conditionally apply green color
          />
          <span
            className={`text-[14px] font-normal ${
              isActive("/user/receive-requests")
                ? "text-primary_green"
                : "text-custom_black"
            }`}
          >
            {t("receive")}
          </span>
        </Link>

        {/* Profile Link */}
        <Link to="/user/profile" className="flex flex-col items-center">
          <IconUser
            size={20}
            className={
              isActive("/user/profile")
                ? "text-primary_green"
                : "text-custom_black"
            } // Conditionally apply green color
          />
          <span
            className={`text-[14px] font-normal ${
              isActive("/user/profile")
                ? "text-primary_green"
                : "text-custom_black"
            }`}
          >
            {t("profile")}
          </span>
        </Link>
      </div>
    </div>
  );
};

export default BottomNavBar;
