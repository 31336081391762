import {
  signInWithEmailAndPassword,
  getAuth,
  createUserWithEmailAndPassword,
  signOut,
  deleteUser,
} from "firebase/auth";
import { app } from "../../firebase/firebase";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";

const auth = getAuth(app);
const db = getFirestore(app);

export const loginUser = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    console.log("User logged in:", user);
    return user;
  } catch (error) {
    console.error("Login error:", error);
    throw new Error(error.message || "Login failed");
  }
};

export const registerUser = async (
  email,
  password,
  mobile
  // nic,
  // gender,
  // year,
  // month,
  // day
) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    // Store user data in Firestore
    await setDoc(doc(db, "users", user.email), {
      email,
      mobile,
      // nic,
      // gender,
      // year,
      // month,
      // day,
      isAccountApproved: false,
      isAccountRejected: false,
      isNicApproved: false,
      isNicRejected: false,
      createdAt: new Date(),
    });

    return user;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const logoutUser = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteAccount = async () => {
  try {
    const user = auth.currentUser;
    if (user) {
      await deleteUser(user);
    } else {
      throw new Error("No user is currenlt signed in");
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const storePersonalDetails = async (email, personalDetails) => {
  try {
    await updateDoc(doc(db, "users", email), {
      ...personalDetails,
      updatedAt: new Date(),
    });
  } catch (error) {
    throw new Error(error.message);
  }
};

export const storeProfileDetails = async (email, profileDetails) => {
  try {
    await updateDoc(doc(db, "users", email), {
      ...profileDetails,
      updatedAt: new Date(),
    });
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getProfileDetails = async (email) => {
  try {
    const docRef = doc(db, "users", email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      throw new Error("No such document!");
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
