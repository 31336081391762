import React, { useState, useEffect } from "react";
import { CustomButton, CustomInput, Footer } from "../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { blackLogo } from "../../assets/images";
import { registerUser } from "../../services/auth/authService";

const NICnumber = () => {
  const location = useLocation();
  const { email, password, mobile, nic } = location.state || {};
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  // const [year, setYear] = useState("");
  // const [month, setMonth] = useState("");
  // const [day, setDay] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (nic) {
      const parsedData = parseNIC(nic);
      setDob(parsedData.dob);
      setGender(parsedData.gender);
      // setYear(parsedData.year);
      // setMonth(parsedData.month);
      // setDay(parsedData.day);
    }
  }, [nic]);

  const parseNIC = (nic: string) => {
    let year = "";
    let month = "";
    let day = "";
    let gender = "";

    if (nic.length === 9) {
      year = "19" + nic.substring(0, 2);
      const days = parseInt(nic.substring(2, 5), 10);
      gender = days > 500 ? "Female" : "Male";
      const dayOfYear = days > 500 ? days - 500 : days;
      const date = new Date(parseInt(year, 10), 0);
      date.setDate(dayOfYear);
      month = ("0" + (date.getMonth() + 1)).slice(-2);
      day = ("0" + date.getDate()).slice(-2);
    } else if (nic.length === 12) {
      year = nic.substring(0, 4);
      const days = parseInt(nic.substring(4, 7), 10);
      gender = days > 500 ? "Female" : "Male";
      const dayOfYear = days > 500 ? days - 500 : days;
      const date = new Date(parseInt(year, 10), 0);
      date.setDate(dayOfYear);
      month = ("0" + (date.getMonth() + 1)).slice(-2);
      day = ("0" + date.getDate()).slice(-2);
    }

    return {
      dob: `${day}-${month}-${year}`,
      gender,
      year,
      month,
      day,
    };
  };

  const handleRegister = async () => {
    setError("");
    setLoading(true);
    try {
      await registerUser(
        email,
        password,
        mobile
        // nic,
        // gender,
        // year,
        // month,
        // day
      );
      navigate("/register/personal-details");
    } catch (err: any) {
      console.error("Registration failed:", err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <nav className="w-[100%] z-[999] bg-transparent h-[60px] lg:h-[80px] px-8 lg:px-[100px] flex flex-row items-center justify-between border-b-[0.5px] border-[#DDDDDD] font-DMSans">
        <div className="flex items-center gap-2 ">
          <img src={blackLogo} alt="" className="h-[50px] lg:h-[60px]" />
        </div>
      </nav>

      {/* Body */}
      <div className="flex-grow">
        <div className="px-8 lg:px-[100px] py-8 flex items-center justify-center font-DMSans">
          <div
            className="bg-white p-4 flex flex-col  rounded-lg w-full lg:w-[400px] px-4 lg:px-12 py-8"
            style={{
              boxShadow: "0.1px 0.1px 2px #AFAFAF",
            }}
          >
            <div className="flex flex-col justify-start items-start w-full gap-2">
              <CustomInput
                label="NIC number"
                placeholder=""
                value={nic}
                disabled={true}
                errorMessage={error}
              />
            </div>
            <div className="flex flex-col justify-start items-start w-full gap-2">
              <CustomInput
                label="Birthday"
                placeholder=""
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                disabled={true}
                errorMessage={error}
              />
            </div>
            <div className="flex flex-col justify-start items-start w-full gap-2 pb-2">
              <CustomInput
                label="Gender"
                placeholder=""
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                disabled={true}
                errorMessage={error}
              />
            </div>

            <CustomButton
              text={loading ? "Loading..." : "Register"}
              onClick={handleRegister}
              disabled={loading}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NICnumber;
