import React, { useState } from "react";
import UserDetailsModal from "../modals/UserDetailsModal";
import { menAvatar, womenAvatar } from "../../assets/images";
import { useTranslation } from "react-i18next";
import { calculateAge } from "../../utils/utils";

interface ProposalCardProps {
  photo: string;
  name: string;
  day: string;
  month: string;
  year: string;
  district: string;
  job: string;
  height: string;
  weight: string;
  race: string;
  caste: string;
  religion: string;
  education: string;
  income: string;
  marriage: string;
  alcohol: string;
  brothers: string;
  sisters: string;
  gender: string;
  otherDetails: string;
}

const ProposalCard: React.FC<ProposalCardProps> = ({
  photo,
  name,
  day,
  month,
  year,
  district,
  job,
  height,
  weight,
  race,
  caste,
  religion,
  education,
  income,
  marriage,
  alcohol,
  brothers,
  sisters,
  gender,
  otherDetails,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleCardClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const defaultAvatar = gender === "Male" ? menAvatar : womenAvatar;
  const age = calculateAge(year, month, day);
  return (
    <>
      <div
        className="bg-white p-4 flex flex-row items-center gap-4 rounded-lg min-w-[310px] border border-golden cursor-pointer"
        style={{
          boxShadow: "1px 1px 5px #AFAFAF",
        }}
        onClick={handleCardClick}
      >
        <img
          src={photo || defaultAvatar}
          alt={name}
          className="w-20 h-20 object-cover rounded-lg border"
        />
        <div className="flex flex-col w-[180px] justify-center">
          <h3 className="text-[16px]  font-medium text-custom_black pb-2">
            {name}
          </h3>

          <div className="flex flex-row justify-between items-center pb-1">
            <p className="text-[12px] font-[400] text-gray-600">
              {age} {t("years")}
            </p>
            <p className="text-[12px] font-[400] text-gray-600">
              {t(district)}
            </p>
          </div>

          <div className="flex flex-row justify-between items-center">
            <p className="text-[12px] font-[400] text-gray-600">{t(job)}</p>
            <p className="text-[12px] font-[400] text-gray-600">{t(height)}</p>
          </div>
        </div>
      </div>

      <UserDetailsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        user={{
          photo,
          name,
          day,
          month,
          year,
          district,
          job,
          height,
          weight,
          race,
          caste,
          religion,
          education,
          income,
          marriage,
          alcohol,
          brothers,
          sisters,
          gender,
          otherDetails,
        }}
      />
    </>
  );
};

export default ProposalCard;
