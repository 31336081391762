import React from "react";
import { IconCircleDashedCheck } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

interface HomeCardProps {
  title: string;
  description: string;
  iconSize?: number;
}

const HomeCard: React.FC<HomeCardProps> = ({
  title,
  description,
  iconSize = 24,
}) => {
  const { t } = useTranslation();
  return (
    <div className="bg-white p-4 rounded-2xl">
      <div className="flex flex-row justify-start items-start gap-2">
        <IconCircleDashedCheck size={iconSize} className="text-primary_green" />
        <div className="flex flex-col justify-start items-start">
          <h4 className="text-[14px] font-medium text-custom_black">
            {t(title)}
          </h4>
          <p className="text-[#8B8B8B] font-light text-[12px]">
            {t(description)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeCard;
