import React, { useEffect, useState } from "react";
import {
  Navbar,
  BottomNavBar,
  CustomInput,
  CustomDropdown,
  CustomButton,
} from "../../components";
import { imagePicker, menAvatar, womenAvatar } from "../../assets/images";
import {
  districts,
  job,
  race,
  caste,
  religion,
  marriageOptions,
  education,
  incomeOptions,
  heightOptions,
  weightOptions,
  alcohol,
  brothersOptions,
  sistersOptions,
} from "../../const/DropdownOptions";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/authContexts";
import {
  getProfileDetails,
  storeProfileDetails,
} from "../../services/auth/authService";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { message } from "antd";
import { Commet } from "react-loading-indicators";
import { useTranslation } from "react-i18next";

interface ProfileDetailsType {
  name: string;
  mobile: string;
  gender: string;
  day: string;
  month: string;
  year: string;
  height: string;
  weight: string;
  alcohol: string;
  brothers: string;
  sisters: string;

  district: string;
  job: string;
  race: string;
  caste: string;
  religion: string;
  education: string;
  marriage: string;
  income: string;
  profilePicture?: string;
  otherDetails: string;
}

const Profile = () => {
  const { currentUser } = useAuth();
  const [name, setName] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedJob, setSelectedJob] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState("");
  const [selectedRace, setSelectedRace] = useState("");
  const [selectedCaste, setSelectedCaste] = useState("");
  const [selectedReligion, setSelectedReligion] = useState("");
  const [selectedEducation, setSelectedEducation] = useState("");
  const [selectedMarriageStatus, setSelectedMarriageStatus] = useState("");
  const [selectedIncome, setSelectedIncome] = useState("");
  const [selectedHeight, setSelectedHeight] = useState("");
  const [selectedWeight, setSelectedWeight] = useState("");
  const [selectedAlcohol, setSelectedAlcohol] = useState("");
  const [selectedBrothers, setSelectedBrothers] = useState("");
  const [selectedSisters, setSelectedSisters] = useState("");
  // const [selectedAssets, setSelectedAssets] = useState("");
  const [dropdownError, setDropdownError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState(menAvatar);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [dataLoading, setDataLoading] = useState(true); // Add a state for data loading
  const navigate = useNavigate();
  const [otherDetails, setOtherDetails] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email);
      } else {
        setUserEmail(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchProfileDetails = async () => {
      if (currentUser) {
        try {
          const profileDetails = await getProfileDetails(currentUser.email);
          setName(profileDetails.name || "");
          setSelectedDistrict(profileDetails.district || "");
          setSelectedJob(profileDetails.job || "");
          setMobile(profileDetails.mobile || "");
          setGender(profileDetails.gender || "");
          setBirthday(
            `${profileDetails.day}-${profileDetails.month}-${profileDetails.year}` ||
              ""
          );
          setSelectedRace(profileDetails.race || "");
          setSelectedCaste(profileDetails.caste || "");
          setSelectedReligion(profileDetails.religion || "");
          setSelectedEducation(profileDetails.education || "");
          setSelectedMarriageStatus(profileDetails.marriage || "");
          setSelectedIncome(profileDetails.income || "");
          setSelectedHeight(profileDetails.height || "");
          setSelectedWeight(profileDetails.weight || "");
          setSelectedAlcohol(profileDetails.alcohol || "");
          setSelectedBrothers(profileDetails.brothers || "");
          setSelectedSisters(profileDetails.sisters || "");
          setOtherDetails(profileDetails.otherDetails || "");
          // setSelectedAssets(profileDetails.assets || "");
          if (profileDetails.profilePicture) {
            setImageUrl(profileDetails.profilePicture);
          } else if (profileDetails.gender === "Male") {
            setImageUrl(menAvatar);
          } else if (profileDetails.gender === "Female") {
            setImageUrl(womenAvatar);
          }
        } catch (error: any) {
          setDropdownError(error.message);
        } finally {
          setDataLoading(false); // Set data loading to false after fetching data
        }
      }
    };

    fetchProfileDetails();
  }, [currentUser]);

  const handleDropdownChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setter(e.target.value);
      if (e.target.value === "") {
        setDropdownError("Please select an option");
      } else {
        setDropdownError("");
      }
    };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    if (!currentUser) {
      console.log("user not found");
      return;
    }

    const [day, month, year] = birthday.split("-");

    const profileDetails: ProfileDetailsType = {
      name,
      district: selectedDistrict,
      job: selectedJob,
      mobile,
      gender,
      day,
      month,
      year,
      race: selectedRace,
      caste: selectedCaste,
      religion: selectedReligion,
      education: selectedEducation,
      marriage: selectedMarriageStatus,
      income: selectedIncome,
      height: selectedHeight,
      weight: selectedWeight,
      alcohol: selectedAlcohol,
      brothers: selectedBrothers,
      sisters: selectedSisters,
      otherDetails,
    };

    setLoading(true);

    try {
      if (selectedImage) {
        const storageRef = ref(storage, `user_avatars/${currentUser.uid}`);
        await uploadBytes(storageRef, selectedImage);
        const downloadURL = await getDownloadURL(storageRef);
        profileDetails.profilePicture = downloadURL;
      }
      await storeProfileDetails(currentUser.email, profileDetails);
      message.success("Profile details saved successfully");
      navigate("/user/proposals");
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col font-DMSans">
      <Navbar isLoggedIn={true} />
      <div className="flex-grow">
        <div className="px-8 lg:px-[100px] py-8  flex flex-col items-center">
          {dataLoading ? (
            <Commet color="#009A74" size="medium" text="" textColor="#009A74" />
          ) : (
            <>
              <div className="relative rounded-[8px] h-[150px] w-[150px] bg-gray-50 border border-golden overflow-hidden">
                <img
                  src={imageUrl}
                  alt=""
                  className="h-full w-full object-cover"
                />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  id="imageInput"
                />
                <label htmlFor="imageInput">
                  <img
                    src={imagePicker}
                    alt=""
                    className="absolute top-0 right-0 h-6 w-6 cursor-pointer"
                  />
                </label>
              </div>

              {/* Textfields */}
              <div className="flex flex-wrap justify-center  pt-6 px-0 lg:px-10 xl:px-[100px]">
                <div className="w-full  lg:w-[300px] lg:px-4">
                  <CustomInput
                    label="Name"
                    placeholder=""
                    value={name}
                    disabled={true}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setName(e.target.value)}
                  />
                </div>
                <div className="w-full lg:w-[300px] lg:px-4">
                  <CustomDropdown
                    label="District"
                    options={districts}
                    value={selectedDistrict}
                    onChange={handleDropdownChange(setSelectedDistrict)}
                    errorMessage={dropdownError}
                  />
                </div>
                <div className="w-full lg:w-[300px] lg:px-4">
                  <CustomDropdown
                    label="Job"
                    options={job}
                    value={selectedJob}
                    onChange={handleDropdownChange(setSelectedJob)}
                    errorMessage={dropdownError}
                  />
                </div>
                <div className="w-full lg:w-[300px] lg:px-4">
                  <CustomInput
                    label="Phone number"
                    placeholder=""
                    value={mobile}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setMobile(e.target.value)}
                  />
                </div>
                <div className="w-full lg:w-[300px] lg:px-4">
                  <CustomInput
                    label="Gender"
                    placeholder=""
                    value={gender}
                    disabled={true}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setGender(e.target.value)}
                  />
                </div>
                <div className="w-full lg:w-[300px] lg:px-4">
                  <CustomInput
                    label="Birthday"
                    placeholder=""
                    value={birthday}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setBirthday(e.target.value)}
                  />
                </div>
                <div className="w-full  lg:w-[300px] lg:px-4">
                  <CustomDropdown
                    label="Race"
                    options={race}
                    value={selectedRace}
                    onChange={handleDropdownChange(setSelectedRace)}
                    errorMessage={dropdownError}
                  />
                </div>
                <div className="w-full  lg:w-[300px] lg:px-4">
                  <CustomDropdown
                    label="Caste"
                    options={caste}
                    value={selectedCaste}
                    onChange={handleDropdownChange(setSelectedCaste)}
                    errorMessage={dropdownError}
                  />
                </div>
                <div className="w-full  lg:w-[300px] lg:px-4">
                  <CustomDropdown
                    label="Religion"
                    options={religion}
                    value={selectedReligion}
                    onChange={handleDropdownChange(setSelectedReligion)}
                    errorMessage={dropdownError}
                  />
                </div>
                <div className="w-full  lg:w-[300px] lg:px-4">
                  <CustomDropdown
                    label="Education status"
                    options={education}
                    value={selectedEducation}
                    onChange={handleDropdownChange(setSelectedEducation)}
                    errorMessage={dropdownError}
                  />
                </div>
                <div className="w-full  lg:w-[300px] lg:px-4">
                  <CustomDropdown
                    label="Marriage status"
                    options={marriageOptions}
                    value={selectedMarriageStatus}
                    onChange={handleDropdownChange(setSelectedMarriageStatus)}
                    errorMessage={dropdownError}
                  />
                </div>
                <div className="w-full  lg:w-[300px] lg:px-4">
                  <CustomDropdown
                    label="Monthly income"
                    options={incomeOptions}
                    value={selectedIncome}
                    onChange={handleDropdownChange(setSelectedIncome)}
                    errorMessage={dropdownError}
                  />
                </div>
                <div className="w-full  lg:w-[300px] lg:px-4">
                  <CustomDropdown
                    label="Height"
                    options={heightOptions}
                    value={selectedHeight}
                    onChange={handleDropdownChange(setSelectedHeight)}
                    errorMessage={dropdownError}
                  />
                </div>
                <div className="w-full  lg:w-[300px] lg:px-4">
                  <CustomDropdown
                    label="Weight"
                    options={weightOptions}
                    value={selectedWeight}
                    onChange={handleDropdownChange(setSelectedWeight)}
                    errorMessage={dropdownError}
                  />
                </div>
                <div className="w-full  lg:w-[300px] lg:px-4">
                  <CustomDropdown
                    label="Alcohol and ciggerate usage"
                    options={alcohol}
                    value={selectedAlcohol}
                    onChange={handleDropdownChange(setSelectedAlcohol)}
                    errorMessage={dropdownError}
                  />
                </div>
                <div className="w-full  lg:w-[300px] lg:px-4">
                  <CustomDropdown
                    label="Brothers"
                    options={brothersOptions}
                    value={selectedBrothers}
                    onChange={handleDropdownChange(setSelectedBrothers)}
                    errorMessage={dropdownError}
                  />
                </div>
                <div className="w-full  lg:w-[300px] lg:px-4">
                  <CustomDropdown
                    label="Sisters"
                    options={sistersOptions}
                    value={selectedSisters}
                    onChange={handleDropdownChange(setSelectedSisters)}
                    errorMessage={dropdownError}
                  />
                </div>
                <div className="w-full  lg:w-[300px] lg:px-4">
                  <label className="block text-[14px] font-[400] text-custom_black mb-1">
                    {t("Other Details (Optional)")}
                  </label>
                  <textarea
                    value={otherDetails}
                    onChange={(e) => setOtherDetails(e.target.value)}
                    placeholder={t("Enter any additional details")}
                    className="w-full px-4 py-2 border rounded-md text-[14px] border-gray-300 focus:outline-none focus:border-[#009A74] hover:border-[#009A74]"
                  />
                </div>
              </div>
              <div className="text-[12px] text-gray-400 font-normal py-4">
                *Filling all the data will increase the opportunity of finding a
                partner
              </div>
              <div className="flex items-center justify-center mb-12">
                <CustomButton
                  text={loading ? "Loading..." : "Save"}
                  width="300px"
                  onClick={handleSave}
                  disabled={loading}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <BottomNavBar />
    </div>
  );
};

export default Profile;
