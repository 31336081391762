import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCXZSK8VsDNsm77W96GsS8lTqRQy9AVIn0",
  authDomain: "sithumina-6e485.firebaseapp.com",
  projectId: "sithumina-6e485",
  storageBucket: "sithumina-6e485.appspot.com",
  messagingSenderId: "213904258577",
  appId: "1:213904258577:web:d369834fe4e18a395363b0",
  measurementId: "G-KWQTQGC72Y",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);

export { app, auth, storage, db };
