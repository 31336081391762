import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

interface NavbarLinkProps {
  text: string;
  textColor?: string;
  icon?: React.ReactNode;
  iconSize?: string;
  onClick?: () => void;
  link?: string;
  width?: string;
}

const NavbarText: React.FC<NavbarLinkProps> = ({
  text,
  textColor = "white",
  icon,
  iconSize = "14px",
  onClick,
  link,
  width,
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  // Check if the current page is active based on the route
  const isActive = location.pathname === link;

  // Apply green color if active
  const activeColor = isActive ? "#FFAD30" : textColor;

  const buttonContent = (
    <div
      onClick={onClick}
      className="px-2 py-1  lg:py-2 flex justify-center "
      style={{
        color: activeColor, // Use activeColor for both icon and text
        fontSize: "14px",
        fontWeight: "500",
        display: "flex",
        alignItems: "center",
        gap: "4px",
        cursor: "pointer",
        borderRadius: "8px",
        textAlign: "center",
        width: width,
      }}
    >
      {icon && (
        <span style={{ fontSize: iconSize, color: activeColor }}>{icon}</span>
      )}
      {t(text)}
    </div>
  );

  // If link prop is provided, wrap the button in Link; otherwise, render the button as is
  return link ? (
    <Link to={link} style={{ textDecoration: "none" }}>
      {buttonContent}
    </Link>
  ) : (
    buttonContent
  );
};

export default NavbarText;
