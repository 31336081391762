import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface DropdownProps {
  label?: string;
  options: { label: string }[];
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
  errorMessage?: string;
}

const CustomDropdown: React.FC<DropdownProps> = ({
  label,
  options,
  value,
  onChange,
  placeholder = "-",
  errorMessage,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full mb-4">
      {label && (
        <label
          htmlFor={label}
          className="text-[14px] font-[400] text-zinc-600 mb-1"
        >
          {t(label)}
        </label>
      )}

      <select
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={`px-2 py-2 border rounded-md transition-all 
          ${isFocused ? "border-[#009A74] shadow-md" : "border-gray-300"} 
          ${errorMessage ? "border-red-500" : ""}
          focus:outline-none focus:border-[#009A74] hover:border-[#009A74] 
          focus:ring-2 focus:ring-[#009A74] text-[14px] text-custom_black`}
        style={{
          boxShadow: isFocused ? "0 0 5px rgba(0, 154, 116, 0.5)" : "none",
          transition: "border-color 0.3s, box-shadow 0.3s",
        }}
      >
        <option value="" disabled className="text-gray-500">
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option.label} value={option.label}>
            {t(option.label)}
          </option>
        ))}
      </select>

      {/* Error message */}
      {errorMessage && (
        <span className="text-red-500 mt-1 text-sm">{errorMessage}</span>
      )}
    </div>
  );
};

export default CustomDropdown;
