import React, { useEffect, useState } from "react";
import {
  Navbar,
  BottomNavBar,
  TitleText,
  ParagraphText,
} from "../../components";
import { useAuth } from "../../contexts/authContexts";
import { getAllUserDetails } from "../../services/proposal/proposalService";
import BestProposalCard from "../../components/bestProposals/BestProposalCard";
import { getUserDetailsByEmail } from "../../services/userdetails/userDetails";
import { Commet } from "react-loading-indicators";

interface Proposal {
  id: string;
  profilePicture: string;
  name: string;
  day: string;
  month: string;
  year: string;
  district: string;
  job: string;
  height: string;
  weight: string;
  race: string;
  caste: string;
  religion: string;
  education: string;
  income: string;
  marriage: string;
  alcohol: string;
  brothers: string;
  sisters: string;
  gender: string;
  email: string;
  otherDetails: string;
}

const Proposals = () => {
  const { currentUser, loading } = useAuth();
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [proposalsLoading, setProposalsLoading] = useState(true);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (currentUser && currentUser.email) {
        try {
          const details = await getUserDetailsByEmail(currentUser.email);

          setUserDetails(details);
        } catch (error) {
          console.error("Failed to fetch user details:", error);
        }
      }
    };

    if (!loading) {
      fetchUserDetails();
    }
  }, [currentUser, loading]);

  useEffect(() => {
    const fetchProposals = async () => {
      setProposalsLoading(true);
      try {
        const allProposals = await getAllUserDetails();
        let filteredProposals = allProposals;

        console.log("Current user details:", userDetails);

        if (userDetails) {
          filteredProposals = allProposals.filter(
            (proposal) =>
              proposal.gender !== userDetails.gender &&
              proposal.email !== userDetails.email
          );
        }

        setProposals(filteredProposals);
      } catch (error: any) {
        console.error("Failed to fetch proposals:", error.message);
      } finally {
        setProposalsLoading(false);
      }
    };

    if (userDetails) {
      fetchProposals();
    }
  }, [userDetails]);

  if (loading) {
    return <div className="text-black">Loading...</div>;
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar isLoggedIn={true} />
      <div className="flex-grow">
        <div className="px-8 lg:px-[100px] py-8  flex flex-col items-center">
          <div className="flex flex-col justify-center items-center gap-2">
            <TitleText title="Best Proposals" />
            <ParagraphText description="Discover a wide range of thoughtfully crafted proposals, each offering a unique opportunity to find your ideal life partner. Browse through and connect with genuine individuals seeking meaningful connections." />
          </div>

          {/* Proposal cards */}
          <div className="flex flex-wrap justify-center gap-4 lg:gap-8 px-10 py-6">
            {proposalsLoading ? (
              <Commet
                color="#009A74"
                size="medium"
                text=""
                textColor="#009A74"
              />
            ) : (
              proposals.map((proposal) => (
                <BestProposalCard
                  key={proposal.id}
                  photo={proposal.profilePicture}
                  name={proposal.name}
                  day={proposal.day}
                  month={proposal.month}
                  year={proposal.year}
                  district={proposal.district}
                  job={proposal.job}
                  height={proposal.height}
                  weight={proposal.weight}
                  race={proposal.race}
                  caste={proposal.caste}
                  religion={proposal.religion}
                  education={proposal.education}
                  income={proposal.income}
                  marriage={proposal.marriage}
                  alcohol={proposal.alcohol}
                  sisters={proposal.sisters}
                  brothers={proposal.brothers}
                  gender={proposal.gender}
                  email={proposal.email}
                  otherDetails={proposal.otherDetails}
                />
              ))
            )}
          </div>
        </div>
      </div>

      {/* Conditionally render BottomNavBar here if logged in */}
      <BottomNavBar />
    </div>
  );
};

export default Proposals;
