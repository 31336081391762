import React from "react";
import { IconSend, IconX } from "@tabler/icons-react";
import { menAvatar, womenAvatar } from "../../assets/images";
import { useTranslation } from "react-i18next";
import { calculateAge } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

interface UserDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: {
    photo: string;
    name: string;
    day: string;
    month: string;
    year: string;
    district: string;
    job: string;
    height: string;
    weight: string;
    race: string;
    caste: string;
    religion: string;
    education: string;
    income: string;
    marriage: string;
    alcohol: string;
    brothers: string;
    sisters: string;
    gender: string;
    otherDetails: string;
  };
}

const UserDetailsModal: React.FC<UserDetailsModalProps> = ({
  isOpen,
  onClose,
  user,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!isOpen) return null;

  const defaultAvatar = user.gender === "Male" ? menAvatar : womenAvatar;
  const age = calculateAge(user.year, user.month, user.day);

  const goToLogin = () => {
    navigate("/register");
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-white p-8 rounded-lg shadow-lg w-[90%] max-w-[600px]">
        <button
          className="absolute top-4 right-4 text-gray-600"
          onClick={onClose}
        >
          <IconX size={24} />
        </button>
        <div className="flex flex-col items-center">
          <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-start  lg:gap-4 gap-1 w-full max-w-[400px]">
            <img
              src={user.photo || defaultAvatar}
              alt={user.name}
              className="w-32 h-32 object-cover rounded-lg border border-golden mb-2"
            />
            <div className="flex-col flex justify-center items-center lg:items-start w-full text-center lg:text-left">
              <div
                className="w-full  m-2 rounded-lg lg:hidden block"
                style={{ backgroundColor: "rgba(0, 255, 0, 0.2)" }}
              >
                <div className="flex justify-center items-center gap-4 my-1 ">
                  {/* <div
                    className="bg-primary_green rounded-full p-2 cursor-pointer"
                    onClick={goToLogin}
                  >
                    <IconHeart size={24} className="text-golden" />
                  </div> */}
                  <div
                    className="bg-primary_green rounded-full p-2 cursor-pointer"
                    onClick={goToLogin}
                  >
                    <IconSend size={24} className="text-golden" />
                  </div>
                </div>
              </div>
              <h2 className="lg:text-[20px] text-[18px] font-semibold text-custom_black lg:mb-1">
                {user.name}
              </h2>

              <UserDetailRow label={t("details.age")} value={`${age}`} />

              <UserDetailRow
                label={t("details.location")}
                value={t(user.district)}
              />

              <UserDetailRow label={t("Job")} value={t(user.job)} />
            </div>
          </div>
          <div
            className="w-full  m-4 rounded-lg hidden lg:block"
            style={{ backgroundColor: "rgba(0, 255, 0, 0.2)" }}
          >
            <div className="flex justify-center items-center gap-4 my-2 ">
              {/* <div
                className="bg-primary_green rounded-full p-2 cursor-pointer"
                onClick={goToLogin}
              >
                <IconHeart size={24} className="text-golden" />
              </div> */}
              <div
                className="bg-primary_green rounded-full p-2 cursor-pointer"
                onClick={goToLogin}
              >
                <IconSend size={24} className="text-golden" />
              </div>
            </div>
          </div>
          <div className="flex-col flex justify-between items-center mb-2 w-full max-w-[400px]">
            <UserDetailRow label={t("details.height")} value={t(user.height)} />

            <UserDetailRow label={t("details.weight")} value={t(user.weight)} />

            <UserDetailRow label={t("Race")} value={t(user.race)} />

            <UserDetailRow label={t("Caste")} value={t(user.caste)} />

            <UserDetailRow label={t("Religion")} value={t(user.religion)} />

            <UserDetailRow
              label={t("Education status")}
              value={t(user.education)}
            />

            <UserDetailRow label={t("Monthly income")} value={t(user.income)} />

            <UserDetailRow
              label={t("Marriage status")}
              value={t(user.marriage)}
            />

            <UserDetailRow
              label={t("details.alcohol")}
              value={t(user.alcohol)}
            />

            <UserDetailRow
              label={t("details.brothers")}
              value={t(user.brothers)}
            />

            <UserDetailRow
              label={t("details.sisters")}
              value={t(user.sisters)}
            />
            <div className="flex flex-row justify-between items-center mb-1 w-full ">
              <p className="text-custom_black font-[500] text-[14px]">
                <span className="text-gray-600">Other details: </span>
                {user.otherDetails}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface UserDetailRowProps {
  label: string;
  value: string | number;
}

const UserDetailRow: React.FC<UserDetailRowProps> = ({ label, value }) => {
  return (
    <div className="flex flex-row justify-between items-center mb-1 w-full">
      <p className="text-gray-600 font-normal text-[14px]">{label} :</p>
      <p className="text-custom_black font-[500] text-[14px]">{value}</p>
    </div>
  );
};

export default UserDetailsModal;
