import React, { useEffect, useState } from "react";
import {
  BottomNavBar,
  Navbar,
  TitleText,
  ParagraphText,
} from "../../components";
import {
  getAcceptedSendRequests,
  getPendingSendRequests,
  getRejectedSendRequests,
} from "../../services/send_requests/sendRequests";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useTranslation } from "react-i18next";
import SendRequestCard from "../../components/sendRequests/SendRequestCard";
import { Commet } from "react-loading-indicators";
import NoPostsAvailable from "../../components/loaders/NoPost";

export interface RequestData {
  id: string;
  profilePicture?: string;
  name?: string;
  year?: string;
  month?: string;
  day?: string;
  location?: string;
  job?: string;
  height?: string;
  weight?: string;
  race?: string;
  caste?: string;
  religion?: string;
  education?: string;
  income?: string;
  marriage?: string;
  alcohol?: string;
  brothers?: number;
  sisters?: number;
  gender?: string;
  email?: string;
  status?: "pending" | "accepted" | "rejected";
  mobile?: string;
  otherDetails?: string;
}

const SendRequests = () => {
  const [requests, setRequests] = useState<RequestData[]>([]);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [requestsLoading, setRequestsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email);
      } else {
        setUserEmail(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchRequests = async () => {
      setRequestsLoading(true);
      if (userEmail) {
        try {
          const pendingRequests = await getPendingSendRequests(userEmail);
          const acceptedRequests = await getAcceptedSendRequests(userEmail);
          const rejectedRequests = await getRejectedSendRequests(userEmail);

          const combinedRequests = [
            ...pendingRequests.map((request) => ({
              ...request,
              status: "pending" as "pending",
            })),
            ...acceptedRequests.map((request) => ({
              ...request,
              status: "accepted" as "accepted",
            })),
            ...rejectedRequests.map((request) => ({
              ...request,
              status: "rejected" as "rejected",
            })),
          ];

          setRequests(
            combinedRequests.map((request) => ({
              id: request.id,
              profilePicture: request.receiverInfo?.profilePicture || "",
              name: request.receiverInfo?.name || "_",
              day: request.receiverInfo?.day || "_",
              month: request.receiverInfo?.month || "_",
              year: request.receiverInfo?.year || "_",
              location: request.receiverInfo?.district || "_",
              job: request.receiverInfo?.job || "_",
              height: request.receiverInfo?.height || "_",
              weight: request.receiverInfo?.weight || "_",
              race: request.receiverInfo?.race || "_",
              caste: request.receiverInfo?.caste || "_",
              religion: request.receiverInfo?.religion || "_",
              education: request.receiverInfo?.education || "_",
              income: request.receiverInfo?.income || "_",
              marriage: request.receiverInfo?.marriage || "_",
              alcohol: request.receiverInfo?.alcohol || "_",
              brothers: request.receiverInfo?.brothers,
              sisters: request.receiverInfo?.sisters,
              gender: request.receiverInfo?.gender || "_",
              email: request.receiverInfo?.email || "_",
              status: request.status,
              otherDetails: request.receiverInfo?.otherDetails || "_",
            }))
          );
        } catch (error) {
          console.error("Error fetching requests:", error);
        } finally {
          setRequestsLoading(false);
        }
      }
    };

    fetchRequests();
  }, [userEmail]);

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar isLoggedIn={true} />
      <div className="flex-grow">
        <div className="px-8 lg:px-[100px] py-8 flex flex-col items-center">
          <div className="flex flex-col justify-center items-center gap-2">
            <TitleText title={t("sendRequests")} />
            <ParagraphText description={t("sendText")} />
          </div>

          <div className="flex flex-wrap justify-center gap-4 lg:gap-8 px-10 py-6">
            {requestsLoading ? (
              <Commet
                color="#009A74"
                size="medium"
                text=""
                textColor="#009A74"
              />
            ) : requests.length === 0 ? (
              <NoPostsAvailable />
            ) : (
              requests.map((request) => (
                <SendRequestCard
                  key={request.id}
                  photo={request.profilePicture || ""}
                  name={request.name || ""}
                  day={request.day?.toString() || ""}
                  month={request.month?.toString() || ""}
                  year={request.year?.toString() || ""}
                  district={request.location || ""}
                  job={request.job || ""}
                  height={request.height || ""}
                  weight={request.weight || ""}
                  race={request.race || ""}
                  caste={request.caste || ""}
                  religion={request.religion || ""}
                  education={request.education || ""}
                  income={request.income || ""}
                  marriage={request.marriage || ""}
                  alcohol={request.alcohol || ""}
                  sisters={(request.sisters ?? "").toString()}
                  brothers={(request.brothers ?? "").toString()}
                  gender={request.gender || ""}
                  email={request.email || ""}
                  status={request.status || "pending"}
                  mobile={request.mobile || ""}
                  otherDetails={request.otherDetails || ""}
                />
              ))
            )}
          </div>
        </div>
      </div>
      <BottomNavBar />
    </div>
  );
};

export default SendRequests;
